import { FC, memo } from 'react';
import { TableRow, TableCell, TableBody as MuiTableBody } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButton } from 'components/buttons';
import { TableButtonProps } from 'helpers';
import classes from './TableBody.module.scss';
import GroupIcon from 'components/groupIcon/GroupIcon';
import SocietyPicture from 'components/societyPicture/SocietyPicture';

type TableBodyProps = FC<{
    buttons?: TableButtonProps[];
    collection: any;
    headers: string[];
}>;

const TableBody: TableBodyProps = ({ buttons, collection, headers }) => {
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            borderRadius: '8px'
        },
        '& td': {
            border: 'none'
        },
        '& td:first-of-type': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px'
        },
        '& td:last-of-type': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px'
        }
    }));

    return (
        <MuiTableBody>
            {collection.map((item: any, index: number) => {
                return (
                    <StyledTableRow className={classes.styled} key={item.id ?? index}>
                        {headers.map((header, index2) => {
                            if (header === 'image') {
                                return (
                                    <TableCell className={classes.container} key={item[header] ?? index}>
                                        <SocietyPicture src={item[header]} />
                                    </TableCell>
                                );
                            }
                            if (header === 'Girone') {
                                return (
                                    <TableCell key={`${index} ${index2}`}>
                                        <GroupIcon item={item.u14[0]} />
                                    </TableCell>
                                );
                            }
                            if (header === 'Nome') {
                                return (
                                    <TableCell key={`${index} ${index2}`}>
                                        <b>{item.name}</b>
                                    </TableCell>
                                );
                            }
                            if (header === 'ranking') {
                                return (
                                    <TableCell className={classes.ranking}>
                                        <b>{index + 1 + '°'}</b>
                                    </TableCell>
                                );
                            }
                            return (
                                <TableCell
                                    className={item[header].length > 50 ? classes.ellipsis : null}
                                    data-text={item[header] ?? index}
                                    key={item[header] ?? index}
                                >
                                    <b>{item[header]}</b>
                                </TableCell>
                            );
                        })}
                        {buttons
                            ? buttons.map((button: any) => (
                                  <TableCell key={button.id} className={classes.buttons}>
                                      <IconButton
                                          className={button.customClasses}
                                          icon={button.icon}
                                          onClick={button.action(item)}
                                          disabled={
                                              typeof button.disabled === 'function' ? button.disabled(item) : button.disabled
                                          }
                                      />
                                  </TableCell>
                              ))
                            : null}
                    </StyledTableRow>
                );
            })}
        </MuiTableBody>
    );
};

export default memo(TableBody);
