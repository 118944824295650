import { TextButton } from 'components/buttons';
import classes from '../Navbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, groupActions } from 'store';
import { GroupYears } from 'helpers';

const ToggleGroupNavbar = () => {
    const { activeTab } = useSelector((x: RootState) => x.group);
    const dispatch = useDispatch();

    return (
        <div className={classes.groupContainer}>
            <TextButton
                className={activeTab === GroupYears.u14 ? 'active' : 'transparent'}
                onClick={() => dispatch(groupActions.setActiveTab(GroupYears.u14))}
            >
                <h5>RAGAZZI</h5>
            </TextButton>
            <TextButton
                className={activeTab === GroupYears.u16 ? 'active' : 'transparent'}
                onClick={() => dispatch(groupActions.setActiveTab(GroupYears.u16))}
            >
                <h5>ALLIEVI</h5>
            </TextButton>
        </div>
    );
};

export default ToggleGroupNavbar;
