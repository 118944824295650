import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Delete: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24.5" viewBox="0 0 20 24.5">
            <path
                id="delete"
                d="M1666.508,14.75h-6.1V12.5a.75.75,0,0,0-.75-.75h-6a.75.75,0,0,0-.75.75v2.25h-6.4v1.5h2.25V35.5a.75.75,0,0,0,.75.75h14a.75.75,0,0,0,.75-.75V16.25h2.25Zm-12.1-1.5h4.5v1.5h-4.5Zm8.35,21.5h-12.5V16.25h12.5Z"
                transform="translate(-1646.508 -11.75)"
                fill={fill}
            />
        </svg>
    );
};
