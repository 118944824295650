import { FC, useMemo } from 'react';
import classes from './Group.module.scss';
import MatchCard from 'components/matchCard/MatchCard';
import Ranking from './ranking/Ranking';
import { GroupMatchWithTeamsAndSocieties } from 'models';

const GROUP_MATCHES = ['', '', '', '', '', ''];

type GroupProps = FC<{
    groupMatches: GroupMatchWithTeamsAndSocieties[];
}>;

const Group: GroupProps = ({ groupMatches }) => {
    const currentGroupMatches = useMemo(() => (groupMatches!.length > 0 ? groupMatches : GROUP_MATCHES), [groupMatches]);

    return (
        <div className={classes.generalContainer}>
            <Ranking groupMatches={groupMatches} />
            <div className={classes.matchCardContainer}>
                {currentGroupMatches.map((groupMatch, index) => (
                    <div className={classes.group} key={index}>
                        <MatchCard groupMatch={groupMatch as GroupMatchWithTeamsAndSocieties} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Group;
