import { AxiosContext } from './axiosContext';
import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { env } from 'environments';
import {
    FinalStage,
    FinalStageMatchUpdate,
    FinalStageMatchWithTeamsAndSocieties,
    FinalStageMatchesCreation,
    FinalStageTypes
} from 'models';
import { GroupYears } from 'helpers';

interface FakeFinalCreation {
    name: string;
    year: GroupYears;
    type: FinalStageTypes;
    matchDay: number;
}

interface FinalStagesApi {
    getAllFinalStagesMatches: () => Promise<FinalStageMatchWithTeamsAndSocieties[]>;
    createFinalStageMatches: (finalStageMatchesCreation: FinalStageMatchesCreation[], year?: GroupYears) => Promise<FinalStage[]>;
    createFakeFinalStageMatch: (fakeMatchCreation: FakeFinalCreation) => Promise<any>;
    updateFinalTeams: (finalTeamsUpdate: any[]) => Promise<FinalStage[]>;
    updateFinalStageMatch: (finalStageMatchUpdate: FinalStageMatchUpdate) => Promise<FinalStage>;
    updateExistingFinals: (existingFinalsUpdate: any[]) => Promise<any>;
}

const FinalStagesContext = createContext<FinalStagesApi>({} as FinalStagesApi);

const FinalStagesProvider: FC<PropsWithChildren> = ({ children }) => {
    const { getRequest, postRequest, putRequest } = useContext(AxiosContext);

    const getAllFinalStagesMatches = useCallback(async () => {
        const res = await getRequest(`${env.reactAppSocketUrl}${env.apiUrl}/final-stage-matches`);
        return res.data;
    }, [getRequest]);

    const createFinalStageMatches = useCallback(
        async (finalStageMatchesCreation: FinalStageMatchesCreation[], year?: GroupYears) => {
            const { data } = await postRequest(`${env.reactAppSocketUrl}${env.apiUrl}/final-stage-matches`, {
                finalStageMatchesCreation,
                year
            });
            return data;
        },
        [postRequest]
    );

    const createFakeFinalStageMatch = useCallback(
        async (fakeMatchCreation: FakeFinalCreation) => {
            const { data } = await postRequest(`${env.reactAppSocketUrl}${env.apiUrl}/final-stage-matches/fake`, {
                fakeMatchCreation
            });
            return data;
        },
        [postRequest]
    );

    const updateFinalTeams = useCallback(
        async (finalTeamsUpdate: any[]) => {
            const { data } = await putRequest(`${env.reactAppSocketUrl}${env.apiUrl}/final-stage-matches/teams`, {
                finalTeamsUpdate
            });
            return data;
        },
        [putRequest]
    );

    const updateExistingFinals = useCallback(
        async (existingFinalsUpdate: any[]) => {
            const { data } = await putRequest(`${env.reactAppSocketUrl}${env.apiUrl}/final-stage-matches/finals`, {
                existingFinalsUpdate
            });
            return data;
        },
        [putRequest]
    );

    const updateFinalStageMatch = useCallback(
        async (finalStageMatchUpdate: FinalStageMatchUpdate) => {
            const { data } = await putRequest(`${env.reactAppSocketUrl}${env.apiUrl}/final-stage-matches`, {
                finalStageMatchUpdate
            });
            return data;
        },
        [putRequest]
    );

    return (
        <FinalStagesContext.Provider
            value={{
                getAllFinalStagesMatches,
                createFinalStageMatches,
                createFakeFinalStageMatch,
                updateFinalTeams,
                updateFinalStageMatch,
                updateExistingFinals
            }}
        >
            {children}
        </FinalStagesContext.Provider>
    );
};

export { FinalStagesContext, FinalStagesProvider };
