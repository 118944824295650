import { IconTextButton } from 'components/buttons';
import { ZoomIn } from 'components/icons';
import Modal from 'components/modal/Modal';
import { FC, useContext, useMemo, useState } from 'react';
import classes from './CreateSocietyModal.module.scss';
import Form from 'components/form/Form';
import { DEFAULT_PATTERN, FieldProps, GroupYears } from 'helpers';
import { SocietyContext } from 'context';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, societyActions, teamActions } from 'store';
import CropImage from 'components/cropImage/CropImage';
import colors from 'styles/modules/colors.module.scss';

const CreateSocietyModal: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { groups } = useSelector((x: RootState) => x.group);
    const { societies } = useSelector((x: RootState) => x.societies);
    const { createSociety } = useContext(SocietyContext);
    const { image } = useSelector((x: RootState) => x.societies);
    const dispatch = useDispatch();
    const groupOptions14 = useMemo(() => groups.filter(group => group.year === GroupYears.u14).map(g => g.name), [groups]);
    const FIELDS: FieldProps[] = [
        {
            placeholder: 'Nome squadra',
            name: 'name',
            validators: { required: true, pattern: DEFAULT_PATTERN }
        },
        {
            name: 'group',
            validators: { required: false },
            options: groupOptions14
        }
    ];

    async function onSubmitHandler(formValues: any) {
        const u14 = groups.filter(group => group.year === GroupYears.u14).find(g => g.name.includes(formValues.group))?.id;
        const u16 = groups.filter(group => group.year === GroupYears.u16).find(g => g.name.includes(formValues.group))?.id;
        const res = await createSociety({ name: formValues.name, image }, u14!, u16!);
        setIsModalOpen(false);
        dispatch(teamActions.addTeam([res.u14, res.u16]));
        dispatch(societyActions.addSociety(res.society));
        dispatch(societyActions.setImage(''));
        return res;
    }
    return (
        <div className={classes.container}>
            <IconTextButton
                className={`lightPrimary shadow ${classes.main}`}
                icon={<ZoomIn fill={societies?.length === 8 ? colors.gray500 : colors.primary600} />}
                disabled={societies?.length === 8}
                onClick={() => setIsModalOpen(true)}
            >
                Aggiungi Società
            </IconTextButton>
            <Modal title="Aggiungi Società" key="society" isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
                <div className={classes.wrapper}>
                    <CropImage src={image} />
                    <Form buttonLabel="Salva" fields={FIELDS} handleSubmitFunction={onSubmitHandler} />
                </div>
            </Modal>
        </div>
    );
};

export default CreateSocietyModal;
