import { Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import Backoffice from './views/backoffice/Backoffice';
import Login from 'views/login/Login';
import Navbar from 'components/navbar/Navbar';
import { FinalStagesProvider, GroupMatchProvider, SocietyProvider } from 'context';

const Routing = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <Navbar isBackOffice={false} />
                        <GroupMatchProvider>
                            <FinalStagesProvider>
                                <SocietyProvider>
                                    <Home />
                                </SocietyProvider>
                            </FinalStagesProvider>
                        </GroupMatchProvider>
                    </>
                }
            />
            <Route
                path="/backoffice"
                element={
                    <>
                        <Navbar isBackOffice={true} />
                        <GroupMatchProvider>
                            <FinalStagesProvider>
                                <Backoffice />
                            </FinalStagesProvider>
                        </GroupMatchProvider>
                    </>
                }
            />
            <Route path="/login" element={<Login />} />
        </Routes>
    );
};
export default Routing;
