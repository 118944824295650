import { FC, memo, useEffect } from 'react';
import classes from './Notice.module.scss';
import { SetState } from 'helpers';
import { SnackbarAlert } from 'components/icons';
import colors from 'styles/modules/colors.module.scss';

type NoticeProps = FC<{
    message: string;
    isOpen: boolean;
    setIsOpen: SetState<boolean>;
}>;

const Notice: NoticeProps = ({ message, isOpen, setIsOpen }) => {
    useEffect(() => {
        setTimeout(() => setIsOpen(false), 2000);
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div className={classes.backdrop}>
                    <div className={classes.notice}>
                        <SnackbarAlert fill={colors.primary600} />
                        <div className={classes.message}>{message}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default memo(Notice);
