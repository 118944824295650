import { GroupYears } from 'helpers';

export enum GroupType {
    fondente = 'Fondente',
    latte = 'Latte'
}

export interface GroupCreation {
    name: string;
    competitionId: string;
    year: GroupYears;
}

export interface GroupUpdate extends GroupCreation {
    id: string;
}

export interface Group extends GroupUpdate {
    createdAt: Date;
    updatedAt: Date;
}
