import { FinalStagesContext, GroupMatchProvider } from 'context';
import Group from './group/Group';
import classes from './Groups.module.scss';
import { FC, useContext, useMemo, useState } from 'react';
import { FinalStageMatchesCreation, FinalStageTypes, GroupMatchWithTeamsAndSocieties, GroupType } from 'models';
import { TextButton } from 'components/buttons';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useIsBackoffice } from 'hook';
import ConfirmDeleteFinalStagesModal from './group/confirmDeleteFinalStagesModal/ConfirmDeleteFinalStagesModal';
import { GroupYears } from 'helpers';
import Footer from 'components/footer/Footer';

type GroupsProps = FC<{
    groupsMatches: GroupMatchWithTeamsAndSocieties[];
}>;

const Groups: GroupsProps = ({ groupsMatches }) => {
    const { groups, ranking, activeTab } = useSelector((x: RootState) => x.group);
    const { finalStagesMatchesU14, finalStagesMatchesU16 } = useSelector((x: RootState) => x.finalStages);
    const { createFinalStageMatches } = useContext(FinalStagesContext);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

    const fondente = useMemo(() => groupsMatches.filter(match => match.Group.name === GroupType.fondente), [groupsMatches]);
    const latte = useMemo(() => groupsMatches.filter(match => match.Group.name === GroupType.latte), [groupsMatches]);
    const isBackoffice = useIsBackoffice();

    const isDisabled = useMemo(() => {
        if (fondente.length === 0 || latte.length === 0) return true;
        const uncompletedMatches = fondente
            .filter(match => match.homeGoals === null || match.awayGoals === null)
            .concat(latte.filter(match => match.homeGoals === null || match.awayGoals === null));
        return uncompletedMatches.length !== 0;
    }, [fondente, latte]);

    const matchesCreation: FinalStageMatchesCreation[] = useMemo(() => {
        const groupsId = groups.filter(group => group.year === activeTab).map(group => group.id);
        const groupsRanking = ranking.filter(currentRanking => groupsId.includes(currentRanking.groupId));
        return [
            {
                name: '1° - 2° posto',
                homeTeam: groupsRanking[0]?.ranking[0].id,
                awayTeam: groupsRanking[1]?.ranking[1].id,
                year: activeTab,
                type: FinalStageTypes.semifinal
            },
            {
                name: '3° - 4° posto',
                homeTeam: groupsRanking[0]?.ranking[1].id,
                awayTeam: groupsRanking[1]?.ranking[0].id,
                year: activeTab,
                type: FinalStageTypes.semifinal
            },
            {
                name: '5° - 6° posto',
                homeTeam: groupsRanking[0]?.ranking[2].id,
                awayTeam: groupsRanking[1]?.ranking[3].id,
                year: activeTab,
                type: FinalStageTypes.semifinal
            },
            {
                name: '7° - 8° posto',
                homeTeam: groupsRanking[0]?.ranking[3].id,
                awayTeam: groupsRanking[1]?.ranking[2].id,
                year: activeTab,
                type: FinalStageTypes.semifinal
            },
            {
                name: '1° - 2° posto',
                year: activeTab,
                type: FinalStageTypes.final
            },
            {
                name: '3° - 4° posto',
                year: activeTab,
                type: FinalStageTypes.final
            },
            {
                name: '5° - 6° posto',
                year: activeTab,
                type: FinalStageTypes.final
            },
            {
                name: '7° - 8° posto',
                year: activeTab,
                type: FinalStageTypes.final
            }
        ];
    }, [activeTab, groups, ranking]);

    async function onClickHandler() {
        const matches =
            activeTab === GroupYears.u14
                ? finalStagesMatchesU14.filter(match => match.year === activeTab)
                : finalStagesMatchesU16.filter(match => match.year === activeTab);
        const filteredMatches = matches.filter(match => match.home);
        if (filteredMatches.length) {
            setIsConfirmModalOpen(true);
            return;
        }
        await createFinalStageMatches(matchesCreation);
    }

    return (
        <div className={classes.container}>
            <div className={classes.scroll}>
                <GroupMatchProvider>
                    <div>
                        <div className={`${classes.group} ${classes.top}`}>
                            <h5>Cioccolato al latte</h5>
                            <Group groupMatches={latte} />
                        </div>
                        <div className={classes.group}>
                            <h5>Cioccolato fondente</h5>
                            <Group groupMatches={fondente} />
                        </div>
                    </div>
                    {!isBackoffice && <Footer />}
                </GroupMatchProvider>
            </div>
            {isBackoffice && (
                <div>
                    <TextButton className={`primary text shadow ${classes.save}`} disabled={isDisabled} onClick={onClickHandler}>
                        Genera fase II
                    </TextButton>
                    <ConfirmDeleteFinalStagesModal
                        isModalOpen={isConfirmModalOpen}
                        matchesCreation={matchesCreation}
                        setIsModalOpen={setIsConfirmModalOpen}
                    />
                </div>
            )}
        </div>
    );
};

export default Groups;
