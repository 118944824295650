import { TextButton } from 'components/buttons';
import Modal from 'components/modal/Modal';
import SocietyPicture from 'components/societyPicture/SocietyPicture';
import { FinalStagesContext, GroupMatchContext } from 'context';
import { GroupYears, SetState } from 'helpers';
import { useIsBackoffice } from 'hook';
import { FinalStageTypes, GroupMatchUpdate, GroupMatchWithTeamsAndSocieties } from 'models';
import { FC, useContext, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import classes from './EditGroupMatch.module.scss';

type EditGroupMatchModalProps = FC<{
    groupMatch: GroupMatchWithTeamsAndSocieties;
    isModalOpen: boolean;
    setIsModalOpen: SetState<boolean>;
    isFinalStageMatch?: boolean;
    isSemiFinal?: boolean;
    isTop?: boolean;
}>;

const EditGroupMatchModal: EditGroupMatchModalProps = ({
    groupMatch,
    setIsModalOpen,
    isModalOpen,
    isFinalStageMatch,
    isSemiFinal,
    isTop
}) => {
    const { finalStagesMatchesU14, finalStagesMatchesU16 } = useSelector((x: RootState) => x.finalStages);
    const { updateGroupMatch } = useContext(GroupMatchContext);
    const { updateFinalTeams, updateFinalStageMatch, updateExistingFinals } = useContext(FinalStagesContext);
    const isBackoffice = useIsBackoffice();
    const {
        register,
        handleSubmit,
        reset,
        formState: { isValid, isDirty }
    } = useForm({ mode: 'onChange' });

    function resetModal() {
        setIsModalOpen(false);
        reset();
    }

    function formatRequestBody(formValues: any) {
        let requestBody: GroupMatchUpdate = { id: groupMatch.id };
        if (formValues.date) {
            const matchDay = new Date(formValues.date as Date).setHours(
                +formValues.time!.split(':')[0],
                +formValues.time!.split(':')[1]
            );
            requestBody = { ...requestBody, matchDay };
        }
        if (formValues.homeGoals.length > 0 || formValues.awayGoals.length > 0) {
            requestBody = {
                ...requestBody,
                homeGoals: formValues.homeGoals.length === 0 ? 0 : +formValues.homeGoals,
                awayGoals: formValues.awayGoals.length === 0 ? 0 : +formValues.awayGoals
            };
        }
        return requestBody;
    }

    async function updateFinal(formValues: any) {
        if (!formValues?.homeGoals) return;
        const winnerId = +formValues.homeGoals > +formValues.awayGoals ? groupMatch.homeTeam : groupMatch.awayTeam;
        const loserId = +formValues.homeGoals > +formValues.awayGoals ? groupMatch.awayTeam : groupMatch.homeTeam;
        const splittedName = (groupMatch as any).name.split('-');
        let winner: any = { id: winnerId, year: (groupMatch as any).year };
        let loser: any = { id: loserId, year: (groupMatch as any).year };
        if (isTop) {
            winner = { ...winner, match: splittedName[0][0] };
            loser = { ...loser, match: +splittedName[1][1] + 1 };
        } else {
            winner = { ...winner, match: +splittedName[0][0] - 1 };
            loser = { ...loser, match: splittedName[0][0] };
        }
        const oldFinals = findOldFinals(winnerId, loserId);
        if (oldFinals) return await updateExistingFinals(oldFinals);
        const u14match = finalStagesMatchesU14.find(
            match => match.year === GroupYears.u14 && match.type === FinalStageTypes.final && match.homeTeam === winnerId
        );
        const u16match = finalStagesMatchesU14.find(
            match => match.year === GroupYears.u16 && match.type === FinalStageTypes.final && match.homeTeam === winnerId
        );
        if (u14match || u16match) return;
        return await updateFinalTeams([winner, loser]);
    }

    function findOldFinals(winnerId: string, loserId: string) {
        let finals;
        if ((groupMatch as any).year === GroupYears.u14)
            finals = finalStagesMatchesU14.filter(match => match.year === GroupYears.u14 && match.type === FinalStageTypes.final);
        else
            finals = finalStagesMatchesU16.filter(match => match.year === GroupYears.u16 && match.type === FinalStageTypes.final);
        const firstMatch = finals.find(
            match =>
                (match.homeTeam === winnerId || match.awayTeam === winnerId) &&
                (match.name.includes('1') || match.name.includes('5'))
        );
        const secondMatch = finals.find(
            match =>
                (match.homeTeam === loserId || match.awayTeam === loserId) &&
                (match.name.includes('3') || match.name.includes('7'))
        );
        if (firstMatch && secondMatch) return;
        const oldFirstMatch = finals.find(
            match => (match.homeTeam === winnerId || match.awayTeam === winnerId) && match.home && match.away
        );
        const oldSecondMatch = finals.find(
            match => (match.homeTeam === loserId || match.awayTeam === loserId) && match.home && match.away
        );
        if (oldFirstMatch && oldSecondMatch)
            return [
                { oldMatch: oldFirstMatch?.id, winnerId },
                { oldMatch: oldSecondMatch?.id, loserId }
            ];
    }

    async function onSaveHandler(formValues: any) {
        let res;
        if (!isFinalStageMatch) {
            res = await updateGroupMatch(formatRequestBody(formValues));
            if (!res) return;
        } else {
            if (isSemiFinal) res = await updateFinal(formValues);
            res = await updateFinalStageMatch(formatRequestBody(formValues) as any);
        }
        if (!res) return;
        resetModal();
    }

    const defaultDateValue = useMemo(
        () => (groupMatch?.matchDay ? new Date(groupMatch?.matchDay).toISOString().split('T')[0] : ''),
        [groupMatch]
    );

    const date = useMemo(() => {
        if (!groupMatch?.matchDay) return;
        const date = new Date(groupMatch?.matchDay!);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const realDate = `${day}.${month}.${year}`;
        return realDate;
    }, [groupMatch]);

    const defaultTimeValue = useMemo(() => {
        if (!groupMatch?.matchDay) return '';
        const date = new Date(groupMatch?.matchDay);
        const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
        return `${hours}:${minutes}`;
    }, [groupMatch]);

    const winner = useCallback(
        (isHome: boolean) => {
            if (!groupMatch || groupMatch.homeGoals === null || groupMatch.awayGoals === null) return 'none';
            const scoreDifference = groupMatch?.homeGoals - groupMatch?.awayGoals;
            if (isNaN(scoreDifference)) return 'none';
            if (scoreDifference === 0) return 'x';
            if ((isHome && scoreDifference > 0) || (!isHome && scoreDifference < 0)) return 'w';
            return 'l';
        },
        [groupMatch]
    );

    if (groupMatch === ('' as any)) return <></>;

    return (
        <Modal customClasses={classes.editGroupMatchModal} handleClose={resetModal} isOpen={isModalOpen} title="Partita">
            <form onSubmit={handleSubmit(onSaveHandler)}>
                <div className={`${classes.homeGoals} ${!isBackoffice ? classes.homeGoalsTransparent : classes.gray} `}>
                    <div data-score={winner(true)} className={` ${classes.homeGoal} ${classes.homeGoalLeft}`}>
                        <input
                            disabled={!isBackoffice || groupMatch?.home === null || groupMatch?.away === null}
                            defaultValue={groupMatch?.homeGoals ?? ''}
                            placeholder="-"
                            type="number"
                            {...register('homeGoals', { max: 100, min: 0 })}
                        />
                        {groupMatch ? (
                            <SocietyPicture size="medium" src={groupMatch?.home?.Society.image} />
                        ) : (
                            <div className={classes.teamLogo}></div>
                        )}
                    </div>
                    <div data-score={winner(false)} className={` ${classes.homeGoal} ${classes.homeGoalRight}`}>
                        {groupMatch ? (
                            <SocietyPicture size="medium" src={groupMatch?.away?.Society.image} />
                        ) : (
                            <div className={classes.teamLogo}></div>
                        )}
                        <input
                            disabled={!isBackoffice || groupMatch?.home === null || groupMatch?.away === null}
                            defaultValue={groupMatch?.awayGoals ?? ''}
                            placeholder="-"
                            type="number"
                            {...register('awayGoals', { max: 100, min: 0 })}
                        />
                    </div>
                </div>
                <br />
                <div className={classes.societyName}>
                    <div>
                        <p>
                            <b>{groupMatch?.home?.Society.name}&nbsp;</b>
                        </p>
                    </div>
                    <div>-</div>
                    <div>
                        <p>
                            <b>&nbsp;{groupMatch?.away?.Society.name}</b>
                        </p>
                    </div>
                </div>
                <br />
                {isBackoffice ? (
                    <div className={classes.calendar}>
                        <input
                            placeholder="Data"
                            type="date"
                            defaultValue={defaultDateValue}
                            disabled={!isBackoffice}
                            {...register('date', { max: '2024/12/31' })}
                        />
                        <input type="time" defaultValue={defaultTimeValue} disabled={!isBackoffice} {...register('time')}></input>
                    </div>
                ) : (
                    <div className={classes.homeCalendar}>
                        {date} - {defaultTimeValue}
                    </div>
                )}
                <br />
                {isBackoffice && (
                    <div className={classes.buttons}>
                        <TextButton className="transparent shadow" type="button" onClick={resetModal}>
                            Annulla
                        </TextButton>
                        <TextButton className="primary text shadow" disabled={!isValid || !isDirty} type="submit">
                            Salva
                        </TextButton>
                    </div>
                )}
            </form>
        </Modal>
    );
};

export default EditGroupMatchModal;
