import { FC, useCallback, useMemo, useState } from 'react';
import classes from './MatchCard.module.scss';
import { FinalStageTypes, GroupMatchWithTeamsAndSocieties } from 'models';
import SocietyPicture from 'components/societyPicture/SocietyPicture';
import EditGroupMatchModal from 'components/editGroupMatchModal/EditGroupMatchModal';
import EditFinalStageMatchesModal from 'components/edtiFinalStageMatchesModal/EditFinalStageMatchesModal';

type MatchCardProp = FC<{
    customClasses?: string;
    groupMatch?: GroupMatchWithTeamsAndSocieties;
    isFinalStageMatch?: boolean;
    isSemifinal?: boolean;
    isTop?: boolean;
    matchName?: string;
    matchType?: FinalStageTypes;
}>;

const getGoal = (goal?: number) => {
    if (goal === undefined || goal === null || isNaN(goal!)) return <>-</>;
    return goal;
};

const MatchCard: MatchCardProp = ({ customClasses, groupMatch, isFinalStageMatch, isTop, isSemifinal, matchName, matchType }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEditFinalModalOpen, setIsEditFinalModalOpen] = useState<boolean>(false);

    function onClickHandler() {
        if (isFinalStageMatch && !groupMatch) {
            setIsEditFinalModalOpen(true);
            return;
        }
        setIsModalOpen(true);
    }

    const date = useMemo(() => {
        if (!groupMatch?.matchDay) return;
        const date = new Date(groupMatch?.matchDay!);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const realDate = `${day}.${month}.${year}`;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${realDate} - ${date.getHours()}:${minutes}`;
    }, [groupMatch]);

    const homeGoals = useMemo(() => getGoal(groupMatch?.homeGoals), [groupMatch]);

    const awayGoals = useMemo(() => getGoal(groupMatch?.awayGoals), [groupMatch]);

    const winner = useCallback(
        (isHome: boolean) => {
            if (!groupMatch || groupMatch.homeGoals === null || groupMatch.awayGoals === null) return 'none';
            const scoreDifference = groupMatch?.homeGoals - groupMatch?.awayGoals;
            if (isNaN(scoreDifference)) return 'none';
            if (scoreDifference === 0) return 'x';
            if ((isHome && scoreDifference > 0) || (!isHome && scoreDifference < 0)) return 'w';
            return 'l';
        },
        [groupMatch]
    );

    return (
        <>
            <div className={classes.container} onClick={onClickHandler}>
                <div className={classes.date}>{date}</div>
                <div className={`${classes.card} ${customClasses}`}>
                    <div className={classes.teamContainer} data-score={winner(true)}>
                        {homeGoals}
                        {groupMatch ? (
                            <SocietyPicture src={groupMatch?.home?.Society.image} />
                        ) : (
                            <div className={classes.teamLogo}></div>
                        )}
                    </div>
                    <div className={classes.break}></div>
                    <div className={classes.teamContainer} data-score={winner(false)}>
                        {groupMatch ? (
                            <SocietyPicture src={groupMatch?.away?.Society.image} />
                        ) : (
                            <div className={classes.teamLogo}></div>
                        )}
                        {awayGoals}
                    </div>
                </div>
            </div>
            <EditGroupMatchModal
                isTop={isTop}
                groupMatch={groupMatch!}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isSemiFinal={isSemifinal}
                isFinalStageMatch={isFinalStageMatch}
            />
            <EditFinalStageMatchesModal
                groupMatch={groupMatch!}
                isModalOpen={isEditFinalModalOpen}
                setIsModalOpen={setIsEditFinalModalOpen}
                matchName={matchName!}
                matchType={matchType!}
            />
        </>
    );
};

export default MatchCard;
