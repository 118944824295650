import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { history } from 'helpers';
import { env } from 'environments';
import { UserToken } from 'models/user.model';

export interface AuthState {
    user?: UserToken;
    token?: string;
    tokenExpiration?: number;
    error: any;
}

export interface UpdateUserBody {
    firstName: string | undefined;
    lastName: string | undefined;
}

const name = 'auth';
const initialState: AuthState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.login.fulfilled, (state, action) => login(state, action));
        builder.addCase(extraActions.ssoLogin.fulfilled, (state, action) => login(state, action));
        builder.addCase(extraActions.updateUser.fulfilled, (state, action: PayloadAction<any>) =>
            uploadUserReducer(state, action)
        );
        builder.addDefaultCase((state, action) => {
            state.error = action.error;
        });
    }
});

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createInitialState() {
    const user = localStorage.getItem('user');
    const permissions = localStorage.getItem('permissions');
    const groupName = localStorage.getItem('groupName');

    return {
        user: user && JSON.parse(user),
        token: localStorage.getItem('token') as string,
        permissions: permissions && (JSON.parse(permissions) as string[]),
        tokenExpiration: +(localStorage.getItem('tokenExpiration') as string),
        groupName,
        error: undefined
    } as AuthState;
}

function createReducers() {
    return { logout };

    function logout(state: AuthState) {
        state.user = undefined;
        state.token = undefined;
        state.tokenExpiration = undefined;
        localStorage.clear();
        history.navigate('/');
    }
}

interface LoginParams {
    email: string;
    password: string;
}

interface SsoLoginParams {
    loginToken: string;
}

function createExtraActions() {
    return {
        login: loginThunk(),
        ssoLogin: ssoLoginThunk(),
        updateUser: updateUserThunk()
    };

    function loginThunk() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }: LoginParams) =>
                await axios.post(`${env.reactAppSocketUrl}${env.apiUrl}/auth`, { email, password }).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }

    function ssoLoginThunk() {
        return createAsyncThunk(`${name}/sso-login`, async ({ loginToken }: SsoLoginParams) =>
            axios.post(`${env.reactAppSocketUrl}${env.apiUrl}/sso/login-sso`, { loginToken }).catch(err => {
                throw Error(err.response.data.message);
            })
        );
    }

    function updateUserThunk() {
        return createAsyncThunk(`${name}/users`, async (data: UpdateUserBody) => {
            const token = localStorage.getItem('token') as string;
            return await axios.put(
                `${env.reactAppSocketUrl}${env.apiUrl}/users/self-update`,
                { userUpdate: data },
                { headers: { Authorization: token } }
            );
        });
    }
}

function login(state: AuthState, action: PayloadAction<any>) {
    const user = action.payload.data as UserToken;
    state.token = user.token;
    state.tokenExpiration = user.tokenExpiration;
    state.user = user;
    localStorage.setItem('tokenExpiration', user.tokenExpiration.toString());
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', user.token);
    history.navigate('/backoffice');
}

const uploadUserReducer = (state: AuthState, action: PayloadAction<any>) => {
    const user = JSON.parse(localStorage.getItem('user') as string);
    user.firstName = action.payload.data.user.firstName;
    user.lastName = action.payload.data.user.lastName;
    localStorage.setItem('user', JSON.stringify(user));
    state.user = user;
};
