import { Login, Logout } from 'components/icons';
import { IconButton, IconTextButton } from 'components/buttons';
import classes from '../Navbar.module.scss';
import { FC, useCallback } from 'react';
import ShareModal from './shareModal/ShareModal';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';
import { history } from 'helpers';

type BackofficeNavbarProps = FC<{
    isBackOffice: boolean;
}>;

const BackofficeNavbar: BackofficeNavbarProps = ({ isBackOffice }) => {
    const dispatch = useDispatch();
    const logout = useCallback(() => dispatch(authActions.logout()), [dispatch]);
    const login = useCallback(() => history.navigate('/login'), []);

    return (
        <div className={`${isBackOffice ? classes.colLight : classes.colDark} ${classes.backofficeContainer}`}>
            <ShareModal />
            <h5>{isBackOffice ? 'Backoffice' : 'Home'}</h5>
            {isBackOffice ? (
                <IconButton className={`${classes.toggleLight} ${classes.logout}`} icon={<Logout />} onClick={logout} />
            ) : (
                <IconButton className={`${classes.toggle} `} icon={<Login />} onClick={login} />
            )}
        </div>
    );
};

export default BackofficeNavbar;
