import { Podium } from 'components/icons';
import classes from './Ranking.module.scss';
import { FC, useMemo } from 'react';
import { GroupMatchWithTeamsAndSocieties } from 'models';
import SocietyPicture from 'components/societyPicture/SocietyPicture';
import { useDispatch } from 'react-redux';
import { groupActions } from 'store';

type RankingProps = FC<{
    groupMatches: GroupMatchWithTeamsAndSocieties[];
}>;

function findTeamAndUpdateRanking(id: string, points: number, teams: any[], scoredGoals: number, concededGoals: number) {
    const index = teams.findIndex(team => team.id === id);
    if (index < 0) return;
    teams[index].rank = teams[index].rank + points;
    teams[index].scoredGoals = teams[index].scoredGoals + scoredGoals;
    teams[index].concededGoals = teams[index].concededGoals + concededGoals;
    teams[index].goalDifference = teams[index].scoredGoals - teams[index].concededGoals;
}

const Ranking: RankingProps = ({ groupMatches }) => {
    const dispatch = useDispatch();

    const teams = useMemo(() => {
        if (!groupMatches?.length) return [{}, {}, {}, {}] as any[];
        let teams = groupMatches
            .map(groupMatch => ({ ...groupMatch.home, rank: 0, scoredGoals: 0, concededGoals: 0, goalDifference: 0 }))
            .concat(
                groupMatches.map(groupMatch => ({
                    ...groupMatch.away,
                    rank: 0,
                    scoredGoals: 0,
                    concededGoals: 0,
                    goalDifference: 0
                }))
            );
        teams = teams.filter((team, index, self) => index === self.findIndex(currentTeam => currentTeam.id === team.id));
        groupMatches.forEach(groupMatch => {
            if (groupMatch.homeGoals === null || groupMatch.awayGoals === null) return;
            if (groupMatch.homeGoals > groupMatch.awayGoals) {
                findTeamAndUpdateRanking(groupMatch.home.id, 3, teams, groupMatch.homeGoals, groupMatch.awayGoals);
                findTeamAndUpdateRanking(groupMatch.away.id, 0, teams, groupMatch.awayGoals, groupMatch.homeGoals);
                return;
            }
            if (groupMatch.homeGoals < groupMatch.awayGoals) {
                findTeamAndUpdateRanking(groupMatch.away.id, 3, teams, groupMatch.awayGoals, groupMatch.homeGoals);
                findTeamAndUpdateRanking(groupMatch.home.id, 0, teams, groupMatch.homeGoals, groupMatch.awayGoals);
                return;
            }
            findTeamAndUpdateRanking(groupMatch.home.id, 1, teams, groupMatch.homeGoals, groupMatch.awayGoals);
            findTeamAndUpdateRanking(groupMatch.away.id, 1, teams, groupMatch.awayGoals, groupMatch.homeGoals);
        });
        teams.sort((team1, team2) => {
            if (team1.rank === team2.rank) {
                if (team1.goalDifference === team2.goalDifference) {
                    if (team1.scoredGoals === team2.scoredGoals) return team2.concededGoals - team1.concededGoals;
                    return team2.scoredGoals - team1.scoredGoals;
                }
                return team2.goalDifference - team1.goalDifference;
            }
            return team2.rank - team1.rank;
        });
        dispatch(groupActions.setRanking({ ranking: teams, groupId: groupMatches[0]?.groupId }));
        return teams;
    }, [dispatch, groupMatches]);

    return (
        <div className={classes.rankingContainer}>
            <div className={classes.podium}>
                <Podium />
            </div>
            {teams.map((team, index) => (
                <div key={index} className={`${classes.ranking} ${index % 2 === 0 && classes.gray}`}>
                    {team.Society ? <SocietyPicture src={team.Society?.image} /> : <div className={classes.empty}></div>}
                    <>{index + 1}°</>
                </div>
            ))}
        </div>
    );
};

export default Ranking;
