import { FC, useMemo } from 'react';
import classes from './FinalStage.module.scss';
import GroupCard from './groupCard/GroupCard';
import { FinalStageMatchWithTeamsAndSocieties, FinalStageTypes } from 'models';
import Footer from 'components/footer/Footer';
import { useIsBackoffice } from 'hook';

type FinalStageProps = FC<{
    finalStage: FinalStageMatchWithTeamsAndSocieties[];
}>;

const FinalStage: FinalStageProps = ({ finalStage }) => {
    const semifinals = useMemo(() => finalStage?.filter(match => match.type === FinalStageTypes.semifinal) ?? [], [finalStage]);
    const finals = useMemo(() => finalStage?.filter(match => match.type === FinalStageTypes.final) ?? [], [finalStage]);

    const firstGroupSemifinals = useMemo(
        () => semifinals.filter(final => final.name.includes('1') || final.name.includes('3')),
        [semifinals]
    );
    const secondGroupSemifinals = useMemo(
        () => semifinals.filter(final => final.name.includes('5') || final.name.includes('7')),
        [semifinals]
    );

    const firstGroupFinals = useMemo(
        () => finals.filter(final => final.name.includes('1') || final.name.includes('3')),
        [finals]
    );
    const secondGroupFinals = useMemo(
        () => finals.filter(final => final.name.includes('5') || final.name.includes('7')),
        [finals]
    );

    const isBackoffice = useIsBackoffice();

    return (
        <div className={classes.container}>
            <div className={classes.groupCards}>
                <GroupCard
                    semifinals={firstGroupSemifinals}
                    finals={firstGroupFinals}
                    rankingPositionTop="1° - 2° posto"
                    rankingPositionBottom="3° - 4° posto"
                />
                <GroupCard
                    semifinals={secondGroupSemifinals}
                    finals={secondGroupFinals}
                    rankingPositionTop="5° - 6° posto"
                    rankingPositionBottom="7° - 8° posto"
                />
            </div>
            {!isBackoffice && <Footer />}
        </div>
    );
};

export default FinalStage;
