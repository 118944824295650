import { FC, useMemo, useState, memo } from 'react';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import classes from './GridTable.module.scss';

type GridTableProps = FC<{
    collection: any[];
    headers: (string | GridColDef)[];
    hideFooter?: boolean;
    slimRows?: boolean;
    loading?: boolean;
    autoHeight?: boolean;
    paginationMode?: 'client' | 'server';
    initialSortModel?: any;
    initialPageSize?: number;
    autoPageSize?: boolean;
    onRowClick?: (params: any, event: any, detail: any) => void;
}>;

const GridTable: GridTableProps = ({
    collection,
    headers,
    hideFooter = true,
    slimRows = false,
    loading = false,
    autoHeight = false,
    paginationMode = 'client',
    initialPageSize = 50,
    onRowClick = undefined,
    autoPageSize = false,
    initialSortModel = []
}) => {
    const [paginationModel, setPaginationModel] = useState({ pageSize: initialPageSize, page: 0 });
    const [sortModel, setSortModel] = useState(initialSortModel);
    const columns: GridColDef[] = useMemo(
        () =>
            headers.map(header => {
                if (typeof header === 'string')
                    return { field: header, headerName: header, minWidth: 16, maxWidth: 150, editable: false };
                else return header;
            }),
        [headers]
    );

    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
            backgroundColor: theme.palette.grey[200]
        }
    }));

    return (
        <div className={`${classes.dataGrid} ${slimRows ? classes.slimRows : ''} ${loading ? classes.loading : ''}`}>
            <StripedDataGrid
                sx={{
                    '.MuiDataGrid-iconButtonContainer': {
                        visibility: 'visible'
                    },
                    '.MuiDataGrid-sortIcon': {
                        opacity: 'inherit !important'
                    }
                }}
                getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                getRowId={row => row?.id}
                loading={loading}
                rowSelection={false}
                disableRowSelectionOnClick={true}
                disableColumnFilter={true}
                disableColumnMenu={true}
                rows={collection}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode={paginationMode}
                hideFooter={hideFooter}
                getRowHeight={() => (slimRows ? 'auto' : null)}
                autoHeight={autoHeight}
                sortModel={sortModel}
                onSortModelChange={model => setSortModel(model)}
                onRowClick={onRowClick}
                autoPageSize={autoPageSize}
            />
        </div>
    );
};

export default memo(GridTable);
