import { useForm } from 'react-hook-form';
import { FC, memo, useCallback } from 'react';
import classes from './Form.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FieldProps } from 'helpers';
import { Switch } from '@mui/material';
import Multiselect from 'components/multiselect/Multiselect';
import { IconButton } from 'components/buttons';
import { Delete } from 'components/icons';
import { useSelector } from 'react-redux';
import { AuthState, RootState } from 'store';

type FormProps = FC<{
    canDelete?: boolean;
    fields: FieldProps[];
    handleSubmitFunction: (value: any) => void;
    onChange?: (name: string, value: string) => any;
    buttonLabel: string;
    onClick?: any;
    isLogin?: boolean;
}>;

const Form: FormProps = ({
    isLogin = false,
    onClick,
    canDelete = false,
    fields,
    handleSubmitFunction,
    buttonLabel,
    onChange = () => {}
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({ mode: 'onChange' });
    const onChangeHandler = (field: string) => (value: any) => onChange(field, value);
    const { error } = useSelector<RootState>(x => x.auth) as AuthState;

    const fieldHandler = useCallback(
        (field: FieldProps) => {
            if (field.options) {
                return (
                    <div className={classes.select}>
                        <select
                            defaultValue={field.value}
                            {...register(field.name, {
                                ...field.validators,
                                onChange: onChangeHandler(field.name),
                                disabled: field.disabled
                            })}
                        >
                            {field.options.map((currentOption: any) => (
                                <option key={currentOption} value={currentOption}>
                                    {currentOption}
                                </option>
                            ))}
                        </select>
                        <span>
                            <ArrowDropDownIcon />
                        </span>
                    </div>
                );
            } else if (field.multiSelect) {
                return (
                    <Multiselect
                        field={{
                            id: field.multiSelect.id,
                            options: field.multiSelect.options,
                            defaultValue: field.multiSelect.defaultValue
                        }}
                        register={field.multiSelect.register}
                        onChange={field.multiSelect.onChange}
                    />
                );
            } else if (field.toggle) {
                return (
                    <Switch
                        onClick={field.toggle.onClick}
                        checked={field.toggle.isChecked}
                        {...register(field.name, { ...field.validators })}
                    />
                );
            } else {
                return (
                    <input
                        placeholder={field.placeholder}
                        defaultValue={field.value}
                        id={field.name}
                        className={errors[field.name]?.type && 'inputError'}
                        disabled={field.disabled}
                        {...register(field.name, { ...field.validators })}
                    />
                );
            }
        },
        [errors, register]
    );

    const fieldGenerator = fields.map((field: FieldProps) => {
        return (
            <div
                className={`${!isLogin ? classes.field : classes.fieldLogin} ${error && classes.error} ${
                    field.isSecretPassword && classes.secret
                }`}
                key={field.name}
            >
                {fieldHandler(field)}
            </div>
        );
    });

    return (
        <form onSubmit={handleSubmit(handleSubmitFunction)} className={classes.form}>
            <div className={classes.fields}>{fieldGenerator}</div>
            <div className={canDelete ? classes.buttonsEdit : classes.buttons}>
                {canDelete && (
                    <IconButton onClick={onClick} icon={<Delete />} disabled={!isValid} type="button" className="alert shadow" />
                )}
                <button disabled={!isValid} type="submit" className="text primary shadow">
                    {isLogin ? 'Login' : ' Salva'}
                </button>
            </div>
        </form>
    );
};

export default memo(Form);
