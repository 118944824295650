import { IconButton } from 'components/buttons';
import classes from '../Navbar.module.scss';
import { FinalStages, Groups, Ranking, Work } from 'components/icons';
import { ToggleType } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, societyActions } from 'store';
import { FC } from 'react';
import colors from 'styles/modules/colors.module.scss';

type ToggleButtonsNavbarProps = FC<{
    isBackOffice: boolean;
}>;

const ToggleButtonsNavbar: ToggleButtonsNavbarProps = ({ isBackOffice }) => {
    const { activeTab } = useSelector((x: RootState) => x.societies);
    const dispatch = useDispatch();

    return (
        <div className={classes.visibleContainer}>
            {isBackOffice && (
                <IconButton
                    className={`transparent ${activeTab === ToggleType.backoffice ? classes.toggle : 'mediumLight'}`}
                    icon={<Work fill={activeTab === ToggleType.backoffice ? colors.green500 : colors.gray100} />}
                    onClick={() => dispatch(societyActions.setActiveTab(ToggleType.backoffice))}
                />
            )}
            <IconButton
                className={`transparent ${activeTab === ToggleType.groups ? classes.toggle : 'mediumLight'}`}
                icon={<Groups fill={activeTab === ToggleType.groups ? colors.green500 : colors.gray100} />}
                onClick={() => dispatch(societyActions.setActiveTab(ToggleType.groups))}
            />
            <IconButton
                className={`transparent ${activeTab === ToggleType.finalStages ? classes.toggle : 'mediumLight'}`}
                icon={<FinalStages fill={activeTab === ToggleType.finalStages ? colors.green500 : colors.gray100} />}
                onClick={() => dispatch(societyActions.setActiveTab(ToggleType.finalStages))}
            />
            <IconButton
                className={`transparent ${activeTab === ToggleType.ranking ? classes.toggle : 'mediumLight'}`}
                icon={<Ranking fill={activeTab === ToggleType.ranking ? colors.green500 : colors.gray100} />}
                onClick={() => dispatch(societyActions.setActiveTab(ToggleType.ranking))}
            />
        </div>
    );
};

export default ToggleButtonsNavbar;
