import FirstStage from 'components/firstStage/FirstStage';
import { GroupMatchContext, GroupProvider, SocietyProvider, FinalStagesContext, SocietyContext } from 'context';
import { TeamProvider } from 'context/teamContext';
import { GroupYears, ToggleType } from 'helpers';
import { useIsBackoffice, useSocketListener } from 'hook';
import { FC, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, societyActions, finalStagesActions, groupMatchActions } from 'store';
import classes from './Home.module.scss';
import RankingTable from 'components/rankingTable/RankingTable';
import SecondStage from 'components/secondStage/SecondStage';
import { FinalStageMatchWithTeamsAndSocieties } from 'models';

const Home: FC = () => {
    const { activeTab } = useSelector((x: RootState) => x.societies);
    const { getAllGroupsMatches } = useContext(GroupMatchContext);
    const { getAllFinalStagesMatches } = useContext(FinalStagesContext);
    const { getAllSocieties } = useContext(SocietyContext);
    const isBackoffice = useIsBackoffice();
    const dispatch = useDispatch();

    const updateHandler = useCallback(
        (message: any) => {
            dispatch(groupMatchActions.replaceGroupMatch(message.payload));
        },
        [dispatch]
    );

    const checkMatchYearAndSetFinalStages = (matches: FinalStageMatchWithTeamsAndSocieties[], year?: GroupYears, id?: string) => {
        if (id) {
            const match = matches.find(match => match.id === id);
            dispatch(finalStagesActions.addFinalStageFinals(match!));
            return;
        }
        if (matches?.length && year === GroupYears.u14) {
            const filteredMatches = matches.filter(match => match.year === year);
            dispatch(finalStagesActions.setFinalStageMatchesU14(filteredMatches));
            return;
        }
        const filteredMatches = matches.filter(match => match.year === year);
        dispatch(finalStagesActions.setFinalStageMatchesU16(filteredMatches));
    };

    const updateFinalStageMatchHandler = useCallback(
        (message: any) => {
            dispatch(finalStagesActions.replaceFinalStageMatch(message.payload));
        },
        [dispatch]
    );

    const addGroupsMatches = useCallback(
        (message: any) => dispatch(groupMatchActions.setGroupsMatches(message.payload)),
        [dispatch]
    );

    const addFinalStages = useCallback(
        (message: any) =>
            checkMatchYearAndSetFinalStages(message.payload.finalStagesMatches, message.payload.year, message.payload.finalId),
        [dispatch]
    );

    useSocketListener('update-match', updateHandler);
    useSocketListener('update-final-stage-match', updateFinalStageMatchHandler);
    useSocketListener('competition-created', addGroupsMatches);
    useSocketListener('final-stages-created', addFinalStages);

    useEffect(() => {
        if (!isBackoffice) dispatch(societyActions.setActiveTab(ToggleType.groups));
        (async () => {
            const matches = await getAllGroupsMatches();
            const finalStageMatches = await getAllFinalStagesMatches();
            const u14 = finalStageMatches.filter(match => match.year === GroupYears.u14);
            const u16 = finalStageMatches.filter(match => match.year === GroupYears.u16);
            const societies = await getAllSocieties();
            dispatch(societyActions.setSocieties(societies));
            dispatch(finalStagesActions.setFinalStageMatchesU14(u14));
            dispatch(finalStagesActions.setFinalStageMatchesU16(u16));
            dispatch(groupMatchActions.setGroupsMatches(matches));
        })();
    }, [dispatch, getAllGroupsMatches, isBackoffice]);

    return (
        <div className={`${classes.container} ${activeTab === ToggleType.ranking && classes.shorterNavbar}`}>
            <SocietyProvider>
                <GroupProvider>
                    <TeamProvider>
                        {activeTab === ToggleType.groups && <FirstStage />}
                        {activeTab === ToggleType.ranking && <RankingTable />}
                        {activeTab === ToggleType.finalStages && <SecondStage />}
                    </TeamProvider>
                </GroupProvider>
            </SocietyProvider>
        </div>
    );
};

export default Home;
