import { ToggleType } from 'helpers';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Society } from 'models';

interface SocietyState {
    activeTab: ToggleType;
    image: string;
    societies: Society[];
}

const name = 'societies';
const initialState: SocietyState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const societyActions = { ...slice.actions };
export const societyReducer = slice.reducer;

function createInitialState() {
    return {
        activeTab: ToggleType.backoffice,
        image: '',
        societies: []
    };
}

function createReducers() {
    return { setActiveTab, setImage, setSocieties, addSociety, updateSociety };

    function setSocieties(state: SocietyState, action: PayloadAction<Society[]>) {
        state.societies = action.payload;
    }

    function addSociety(state: SocietyState, action: PayloadAction<Society>) {
        state.societies.push(action.payload);
    }

    function setActiveTab(state: SocietyState, action: PayloadAction<ToggleType>) {
        if (state.activeTab === action.payload) return;
        state.activeTab = action.payload;
    }

    function setImage(state: SocietyState, action: PayloadAction<string>) {
        state.image = action.payload;
    }

    function updateSociety(state: SocietyState, action: PayloadAction<Society>) {
        const societies = state.societies;
        const index = societies.findIndex(s => s.id === action.payload.id);
        if (index === -1) return;
        societies.splice(index, 1, action.payload);
    }
}
