import { Society, SocietyCreation, SocietyUpdate, Team } from 'models';
import { AxiosContext } from './axiosContext';
import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { env } from 'environments';
import { COMPETITION_ID } from 'helpers';

interface CreateSocietyResponse {
    society: Society;
    u14: Team;
    u16: Team;
}

interface UpdateSocietyResponse extends CreateSocietyResponse {
    message: string;
}

interface SocietyApi {
    getAllSocieties: () => Promise<Society[]>;
    createSociety: (societyCreation: SocietyCreation, u14: string, u16: string) => Promise<CreateSocietyResponse>;
    deleteSociety: (id: string) => Promise<string>;
    updateSociety: (societyUpdate: SocietyUpdate, u14: string, u16: string) => Promise<UpdateSocietyResponse>;
}

const SocietyContext = createContext<SocietyApi>({} as SocietyApi);

const SocietyProvider: FC<PropsWithChildren> = ({ children }) => {
    const { postRequest, getRequest, deleteRequest, putRequest } = useContext(AxiosContext);

    const getAllSocieties = useCallback(async () => {
        const { data } = await getRequest(`${env.reactAppSocketUrl}${env.apiUrl}/societies`);
        return data;
    }, [getRequest]);

    const createSociety = useCallback(
        async (societyCreation: SocietyCreation, u14: string, u16: string) => {
            const res = await postRequest(`${env.reactAppSocketUrl}${env.apiUrl}/societies`, {
                societyCreation: { ...societyCreation, competitionId: COMPETITION_ID },
                u14,
                u16
            });
            return res.data;
        },
        [postRequest]
    );

    const deleteSociety = useCallback(
        async (id: string) => {
            const { data } = await deleteRequest(`${env.reactAppSocketUrl}${env.apiUrl}/societies/${id}`);
            return data.message;
        },
        [deleteRequest]
    );

    const updateSociety = useCallback(
        async (societyUpdate: SocietyUpdate, u14: string, u16: string) => {
            const { data } = await putRequest(`${env.reactAppSocketUrl}${env.apiUrl}/societies`, {
                societyUpdate: { ...societyUpdate },
                u14,
                u16
            });
            return data;
        },
        [putRequest]
    );

    return (
        <SocietyContext.Provider value={{ createSociety, getAllSocieties, deleteSociety, updateSociety }}>
            {children}
        </SocietyContext.Provider>
    );
};

export { SocietyContext, SocietyProvider };
