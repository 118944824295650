import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Close: SvgIconProps = ({ fill = colors.gray900 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31.113" height="31.113" viewBox="0 0 31.113 31.113">
            <path
                id="close"
                d="M-129.75,3248v-10.25H-140v-1.5h10.25V3226h1.5v10.25H-118v1.5h-10.25V3248Z"
                transform="translate(2395.678 -2182.131) rotate(45)"
                fill={fill}
            />
        </svg>
    );
};
