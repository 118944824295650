import { TextButton } from 'components/buttons';
import Modal from 'components/modal/Modal';
import { FinalStagesContext } from 'context';
import { GroupYears, SetState } from 'helpers';
import { FinalStageMatchesCreation } from 'models';
import { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, finalStagesActions } from 'store';
import classes from './ConfirmDeleteFinalStagesModal.module.scss';
import Notice from 'components/notice/Notice';

type ConfirmDeleteFinalStagesModalProps = FC<{
    isModalOpen: boolean;
    setIsModalOpen: SetState<boolean>;
    matchesCreation: FinalStageMatchesCreation[];
}>;

const ConfirmDeleteFinalStagesModal: ConfirmDeleteFinalStagesModalProps = ({ isModalOpen, setIsModalOpen, matchesCreation }) => {
    const { activeTab } = useSelector((x: RootState) => x.group);
    const { createFinalStageMatches } = useContext(FinalStagesContext);
    const dispatch = useDispatch();
    const [isNoticeOpen, setIsNoticeOpen] = useState(false);

    async function onClickHandler() {
        if (activeTab === GroupYears.u14) {
            dispatch(finalStagesActions.setFinalStageMatchesU14([]));
        } else {
            dispatch(finalStagesActions.setFinalStageMatchesU16([]));
        }
        const res = await createFinalStageMatches(matchesCreation, activeTab);
        if (!res) return;
        setIsModalOpen(false);
        setIsNoticeOpen(true);
    }

    return (
        <>
            <Modal
                title="Genera fase II"
                customClasses={classes.confirmModal}
                handleClose={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
            >
                <p className={classes.text}>
                    Attenzione! Generando nuovamente gli incontri della fase II verrà sovrascritto qualsiasi incontro e punteggio
                    attualmente presente nella sezione. Desideri procedere?
                </p>
                <div className={classes.actions}>
                    <div className={classes.button}>
                        <TextButton className="text shadow" onClick={() => setIsModalOpen(false)}>
                            Annulla
                        </TextButton>
                    </div>
                    <div className={classes.button}>
                        <TextButton className="primary text" onClick={onClickHandler}>
                            Procedi
                        </TextButton>
                    </div>
                </div>
            </Modal>
            <Notice isOpen={isNoticeOpen} message="Fase II generata correttamente" setIsOpen={setIsNoticeOpen} />
        </>
    );
};

export default ConfirmDeleteFinalStagesModal;
