import { FC, useContext, useMemo, useState } from 'react';
import CreateSocietyModal from './createSocietyModal/CreateSocietyModal';
import { GroupContext, SocietyContext } from 'context';
import { useMount } from 'hook';
import { RootState, societyActions } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import classes from './CompetitionManagement.module.scss';
import { groupActions } from 'store/group.slice';
import { TeamContext } from 'context/teamContext';
import { teamActions } from 'store/team.slice';
import { IconTextButton, TextButton } from 'components/buttons';
import SocietiesTable from './societiesTable/SocietiesTable';
import { GroupMatchCreation } from 'models';
import { GroupMatchContext } from 'context/groupMatchContext';
import { Reload, SnackbarAlert } from 'components/icons';
import colors from 'styles/modules/colors.module.scss';
import Modal from 'components/modal/Modal';

const CompetitionManagement: FC = () => {
    const { getAllSocieties } = useContext(SocietyContext);
    const { getGroups } = useContext(GroupContext);
    const { createGroupsMatches } = useContext(GroupMatchContext);
    const { getAllTeams } = useContext(TeamContext);
    const { groups } = useSelector((x: RootState) => x.group);
    const { teams } = useSelector((x: RootState) => x.team);
    const { groupsMatches } = useSelector((x: RootState) => x.groupsMatches);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(false);
    const dispatch = useDispatch();

    useMount(() => {
        (async () => {
            const societies = await getAllSocieties();
            dispatch(societyActions.setSocieties(societies));
            const groups = await getGroups();
            dispatch(groupActions.setGroups(groups));
            const teams = await getAllTeams();
            dispatch(teamActions.setTeams(teams));
        })();
    });

    async function onClickHandler() {
        setIsConfirmButtonDisabled(true);
        const groupsMatchesCreation: GroupMatchCreation[] = [];
        groups.forEach(group => {
            const teamsId = teams.filter(team => team.groupId === group.id).map(team => team.id);
            groupsMatchesCreation.push({
                team1: teamsId[0],
                team2: teamsId[1],
                team3: teamsId[2],
                team4: teamsId[3],
                groupId: group.id
            });
        });
        await createGroupsMatches(groupsMatchesCreation);
        setIsConfirmButtonDisabled(false);
    }

    async function onDeleteHandler() {
        await createGroupsMatches([]);
        setIsDeleteModalOpen(false);
    }

    const isDisabled = useMemo(() => {
        const currentGroups = groups.map(group => teams.filter(team => team.groupId === group.id));
        return currentGroups[0]?.length !== 4 || currentGroups[1]?.length !== 4 || currentGroups[2]?.length !== 4;
    }, [groups, teams]);

    return (
        <div className={classes.container}>
            <div className={classes.createSociety}>
                <div className={classes.alertHeader}>
                    {!groupsMatches.length ? (
                        <CreateSocietyModal />
                    ) : (
                        <div className={classes.startedCompetition}>
                            <SnackbarAlert fill={colors.alertLight} />
                            <div className={classes.message}>
                                <b>Competizione iniziata</b>
                                <>Resetta la competizione per modificare le società</>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.scroll}>
                    <SocietiesTable />
                </div>
            </div>
            <div className={classes.footer}>
                {!groupsMatches.length ? (
                    <>
                        {isDisabled && (
                            <div className={classes.startedCompetition}>
                                <SnackbarAlert />
                                <div className={classes.message}>
                                    <b>Per generare la fase I inserire 8 società!</b>
                                    Distribuire equamente tra i due gironi
                                </div>
                            </div>
                        )}
                        <div className={classes.save}>
                            <TextButton
                                className={`primary text shadow ${classes.save}`}
                                disabled={isDisabled}
                                onClick={() => setIsModalOpen(true)}
                            >
                                Genera fase I
                            </TextButton>
                        </div>
                        <Modal
                            customClasses={classes.confirmModal}
                            isOpen={isModalOpen}
                            handleClose={() => setIsModalOpen(false)}
                            title="Genera fase I"
                        >
                            <>
                                <div className={classes.text}>
                                    Una volta generata la fase I non sarà più possibile eliminare o modificare le società.
                                    Procedere?
                                </div>
                                <div className={classes.actions}>
                                    <div>
                                        <TextButton
                                            className={`text shadow ${classes.button}`}
                                            onClick={() => setIsModalOpen(!isModalOpen)}
                                        >
                                            Annulla
                                        </TextButton>
                                    </div>
                                    <div>
                                        <TextButton
                                            disabled={isConfirmButtonDisabled}
                                            className={`primary text shadow ${classes.button}`}
                                            onClick={onClickHandler}
                                        >
                                            Procedi
                                        </TextButton>
                                    </div>
                                </div>
                            </>
                        </Modal>
                    </>
                ) : (
                    <>
                        <div className={classes.save}>
                            <IconTextButton
                                icon={<Reload />}
                                className={`alert text shadow ${classes.save}`}
                                disabled={isDisabled}
                                onClick={() => setIsDeleteModalOpen(true)}
                            >
                                Reset Competizione
                            </IconTextButton>
                        </div>
                        <Modal
                            customClasses={classes.confirmModal}
                            isOpen={isDeleteModalOpen}
                            handleClose={() => setIsDeleteModalOpen(false)}
                            title="Reset Competizione"
                        >
                            <>
                                <div className={classes.text}>
                                    Stanno per essere resettate tutte le competizioni della fase I e II, andrà rigenerata la fase
                                    I e riassociate le date degli incontri. Questo consentirà di modificare nuovamente le squadre.
                                    Procedere?
                                </div>
                                <div className={classes.actions}>
                                    <TextButton
                                        className={` light shadow ${classes.button}`}
                                        onClick={() => setIsModalOpen(!isModalOpen)}
                                    >
                                        Annulla
                                    </TextButton>
                                    <TextButton className={`alert text shadow  ${classes.button}`} onClick={onDeleteHandler}>
                                        Procedi
                                    </TextButton>
                                </div>
                            </>
                        </Modal>
                    </>
                )}
            </div>
        </div>
    );
};

export default CompetitionManagement;
