import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GroupYears } from 'helpers';
import { FinalStageMatchWithTeamsAndSocieties } from 'models';
interface FinalStageMatchesState {
    finalStagesMatchesU14: FinalStageMatchWithTeamsAndSocieties[];
    finalStagesMatchesU16: FinalStageMatchWithTeamsAndSocieties[];
}

const name = 'finalStages';
const initialState: FinalStageMatchesState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const finalStagesActions = { ...slice.actions };
export const finalStagesReducer = slice.reducer;

function createInitialState() {
    return {
        finalStagesMatchesU14: [],
        finalStagesMatchesU16: []
    };
}

function createReducers() {
    return { setFinalStageMatchesU14, setFinalStageMatchesU16, addFinalStageFinals, replaceFinalStageMatch };

    function setFinalStageMatchesU14(
        state: FinalStageMatchesState,
        action: PayloadAction<FinalStageMatchWithTeamsAndSocieties[]>
    ) {
        state.finalStagesMatchesU14 = action.payload;
    }

    function setFinalStageMatchesU16(
        state: FinalStageMatchesState,
        action: PayloadAction<FinalStageMatchWithTeamsAndSocieties[]>
    ) {
        state.finalStagesMatchesU16 = action.payload;
    }

    function addFinalStageFinals(state: FinalStageMatchesState, action: PayloadAction<FinalStageMatchWithTeamsAndSocieties>) {
        if (action.payload.year === GroupYears.u14) {
            state.finalStagesMatchesU14.push(action.payload);
            return;
        }
        state.finalStagesMatchesU16.push(action.payload);
    }

    function replaceFinalStageMatch(
        state: FinalStageMatchesState,
        action: PayloadAction<FinalStageMatchWithTeamsAndSocieties[]>
    ) {
        action.payload.forEach(matchUpdate => {
            if (matchUpdate.year === GroupYears.u14) {
                const index = state.finalStagesMatchesU14.findIndex(match => match.id === matchUpdate.id);
                if (index < 0) return;
                state.finalStagesMatchesU14[index] = matchUpdate;
                return;
            }
            const index = state.finalStagesMatchesU16.findIndex(match => match.id === matchUpdate.id);
            if (index < 0) return;
            state.finalStagesMatchesU16[index] = matchUpdate;
        });
    }
}
