import { configureStore } from '@reduxjs/toolkit';
import { groupReducer } from 'store/group.slice';
import { teamReducer } from 'store/team.slice';
import { societyReducer } from 'store/societies.slice';
import { authReducer } from 'store/auth.slice';
import { groupMatchReducer } from './groupMatch.slice';
import { socketReducer } from './socketIO.slice';
import { finalStagesReducer } from './finalStageMatch.slice';

export * from './societies.slice';
export * from './team.slice';
export * from './group.slice';
export * from './auth.slice';
export * from './socketIO.slice';
export * from './groupMatch.slice';
export * from './finalStageMatch.slice';

export const store = configureStore({
    reducer: {
        group: groupReducer,
        team: teamReducer,
        societies: societyReducer,
        auth: authReducer,
        groupsMatches: groupMatchReducer,
        socket: socketReducer,
        finalStages: finalStagesReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
