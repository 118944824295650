import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Vector10: SvgIconProps = ({ fill = colors.primary600 }) => {
    return (
        <svg width="20" height="162" viewBox="0 0 20 162" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 1H13C16.3137 1 19 3.68629 19 7V155C19 158.314 16.3137 161 13 161H0" stroke="#008FC1" strokeWidth="2" />
        </svg>
    );
};
