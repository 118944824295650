import { Edit } from 'components/icons';
import Table from 'components/table/Table';
import { SocietyWithGroups } from 'models';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, societyActions } from 'store';
import EditSocietyModal from './editSocietyModal/EditSocietyModal';

const SocietiesTable = () => {
    const HEADERS: string[] = ['image', 'Nome', 'Girone'];
    const { societies } = useSelector((x: RootState) => x.societies);
    const { groups } = useSelector((x: RootState) => x.group);
    const { teams } = useSelector((x: RootState) => x.team);
    const { groupsMatches } = useSelector((x: RootState) => x.groupsMatches);
    const [societyToEdit, setSocietyToEdit] = useState<SocietyWithGroups | null>(null);
    const dispatch = useDispatch();
    const handleSocietyEdit = useCallback(
        (society: SocietyWithGroups) => () => {
            setSocietyToEdit(society);
            dispatch(societyActions.setImage(society.image));
        },
        [dispatch]
    );
    const buttons = useMemo(
        () =>
            !groupsMatches.length
                ? [
                      {
                          icon: <Edit />,
                          action: handleSocietyEdit,
                          customClasses: 'transparent',
                          id: 'editButton'
                      }
                  ]
                : [],
        [groupsMatches.length, handleSocietyEdit]
    );

    const collection = useMemo(() => {
        if (teams.length === 0) return [];
        return societies.map(society => {
            const u14Team = teams.find(team => team.societyId === society.id && team.year === 'u14');
            const u16Team = teams.find(team => team.societyId === society.id && team.year === 'u16');
            const u14 = groups.find(group => group.id === u14Team?.groupId)?.name;
            const u16 = groups.find(group => group.id === u16Team?.groupId)?.name;
            return { ...society, u14, u16 };
        });
    }, [teams, societies, groups]);

    return (
        <>
            <EditSocietyModal setSociety={setSocietyToEdit} society={societyToEdit} />
            <Table headers={HEADERS} collection={collection} buttons={buttons} />
        </>
    );
};

export default SocietiesTable;
