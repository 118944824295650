import socketio, { Socket } from 'socket.io-client';
import { env } from 'environments';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialSocketState {
    socket: Socket | any;
}

export interface SocketState extends InitialSocketState {
    socket: Socket;
}

export type MessageTopics = 'login';
export type ResponseTopics =
    | 'client'
    | 'update-match'
    | 'update-final-stage-match'
    | 'competition-created'
    | 'final-stages-created';

const name = 'socketIO';
const initialState: InitialSocketState = createInitialState();
const reducers = createReducers();
const slice = createSlice({
    name,
    initialState,
    reducers
});

export const socketActions = { ...slice.actions };
export const socketReducer = slice.reducer;

function createInitialState() {
    const socket = socketio(env.reactAppSocketUrl);
    socket.connect();
    return {
        socket
    } as InitialSocketState;
}

function createReducers() {
    return {
        login,
        sendMessage,
        createListeners,
        removeListener
    };

    function login(state: SocketState) {
        state.socket.emit('msg', { topic: 'login', payload: {} });
    }

    function sendMessage(state: SocketState, action: PayloadAction<{ topic: MessageTopics; payload: any }>) {
        state.socket.emit('msg', action.payload);
    }

    function createListeners(
        state: SocketState,
        action: PayloadAction<{
            topic: ResponseTopics;
            listener: (...args: any[]) => void;
        }>
    ) {
        state.socket?.on(action.payload.topic, action.payload.listener);
    }

    function removeListener(state: SocketState, action: PayloadAction<{ responseTopic: ResponseTopics }>) {
        state.socket?.removeListener(action.payload.responseTopic);
    }
}
