import { AxiosContext } from './axiosContext';
import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { env } from 'environments';
import {
    GroupMatch,
    GroupMatchCreation,
    GroupMatchUpdate,
    GroupMatchWithTeamsAndSocieties,
    UpdateGroupMatchResponse
} from 'models';

interface GroupMatchApi {
    getAllGroupsMatches: () => Promise<GroupMatchWithTeamsAndSocieties[]>;
    createGroupsMatches: (groupsMatchesCreation: GroupMatchCreation[]) => Promise<GroupMatch[]>;
    updateGroupMatch: (groupMatchUpdate: GroupMatchUpdate) => Promise<UpdateGroupMatchResponse>;
}

const GroupMatchContext = createContext<GroupMatchApi>({} as GroupMatchApi);

const GroupMatchProvider: FC<PropsWithChildren> = ({ children }) => {
    const { getRequest, postRequest, putRequest } = useContext(AxiosContext);

    const getAllGroupsMatches = useCallback(async () => {
        const res = await getRequest(`${env.reactAppSocketUrl}${env.apiUrl}/group-matches`);
        return res.data;
    }, [getRequest]);

    const createGroupsMatches = useCallback(
        async (groupsMatchesCreation: GroupMatchCreation[]) => {
            const { data } = await postRequest(`${env.reactAppSocketUrl}${env.apiUrl}/group-matches`, { groupsMatchesCreation });
            return data;
        },
        [postRequest]
    );

    const updateGroupMatch = useCallback(
        async (groupMatchUpdate: GroupMatchUpdate) => {
            const { data } = await putRequest(`${env.reactAppSocketUrl}${env.apiUrl}/group-matches`, { groupMatchUpdate });
            return data;
        },
        [putRequest]
    );

    return (
        <GroupMatchContext.Provider value={{ getAllGroupsMatches, createGroupsMatches, updateGroupMatch }}>
            {children}
        </GroupMatchContext.Provider>
    );
};

export { GroupMatchContext, GroupMatchProvider };
