import classes from '../FinalStage.module.scss';
import { FC, useMemo } from 'react';
import MatchCard from 'components/matchCard/MatchCard';
import Vector from './vector/Vector';
import { FinalStageMatchWithTeamsAndSocieties, FinalStageTypes } from 'models';

type GroupCardProps = FC<{
    rankingPositionTop: string;
    rankingPositionBottom: string;
    semifinals: FinalStageMatchWithTeamsAndSocieties[];
    finals: FinalStageMatchWithTeamsAndSocieties[];
}>;

const GroupCard: GroupCardProps = ({ semifinals, finals, rankingPositionTop, rankingPositionBottom }) => {
    const firstFinal = useMemo(() => {
        const match = finals.find(final => final?.name === rankingPositionTop);
        if (semifinals[0]?.homeGoals && semifinals[1]?.homeGoals) {
            if (finals.length && match) {
                return (
                    <div className={classes.top}>
                        <div className={classes.ranking}>
                            <p>
                                <b>{rankingPositionTop}</b>
                            </p>
                        </div>
                        <MatchCard
                            matchName={rankingPositionTop}
                            matchType={FinalStageTypes.final}
                            isTop={true}
                            isFinalStageMatch={true}
                            groupMatch={match as any}
                            customClasses={classes.matchCard}
                        />
                    </div>
                );
            }
            const { winners } = createFakeMatch(semifinals[0], semifinals[1]);
            return (
                <div className={classes.top}>
                    <div className={classes.ranking}>
                        <p>
                            <b>{rankingPositionTop}</b>
                        </p>
                    </div>
                    <MatchCard
                        matchName={rankingPositionTop}
                        matchType={FinalStageTypes.final}
                        isTop={true}
                        isFinalStageMatch={true}
                        groupMatch={winners as any}
                        customClasses={classes.matchCard}
                    />
                </div>
            );
        }
        if (match && !match.homeGoals) {
            return (
                <div className={classes.top}>
                    <div className={classes.ranking}>
                        <p>
                            <b>{rankingPositionTop}</b>
                        </p>
                    </div>
                    <MatchCard
                        matchName={rankingPositionTop}
                        matchType={FinalStageTypes.final}
                        isTop={true}
                        groupMatch={match as any}
                        isFinalStageMatch={true}
                        customClasses={classes.matchCard}
                    />
                </div>
            );
        }
        return (
            <div className={classes.top}>
                <div className={classes.ranking}>
                    <p>
                        <b>{rankingPositionTop}</b>
                    </p>
                </div>
                <MatchCard
                    matchName={rankingPositionTop}
                    matchType={FinalStageTypes.final}
                    isTop={true}
                    isFinalStageMatch={true}
                    customClasses={classes.matchCard}
                />
            </div>
        );
    }, [finals, semifinals]);

    const secondFinal = useMemo(() => {
        const match = finals.find(final => final?.name === rankingPositionBottom);
        if (match && !match.homeGoals) {
            return (
                <div className={classes.top}>
                    <div className={classes.ranking}>
                        <p>
                            <b>{rankingPositionBottom}</b>
                        </p>
                    </div>
                    <MatchCard
                        matchName={rankingPositionBottom}
                        matchType={FinalStageTypes.final}
                        groupMatch={match as any}
                        isFinalStageMatch={true}
                        customClasses={classes.matchCard}
                    />
                </div>
            );
        }
        if (semifinals[0]?.homeGoals && semifinals[1]?.homeGoals) {
            if (finals.length && match) {
                return (
                    <>
                        <div className={classes.ranking}>
                            <p>
                                <b>{rankingPositionBottom}</b>
                            </p>
                        </div>
                        <MatchCard
                            matchName={rankingPositionBottom}
                            matchType={FinalStageTypes.final}
                            isFinalStageMatch={true}
                            groupMatch={match as any}
                            customClasses={classes.matchCard}
                        />
                    </>
                );
            }
            const { losers } = createFakeMatch(semifinals[0], semifinals[1]);
            return (
                <>
                    <div className={classes.ranking}>
                        <p>
                            <b>{rankingPositionBottom}</b>
                        </p>
                    </div>
                    <MatchCard
                        matchName={rankingPositionBottom}
                        matchType={FinalStageTypes.final}
                        isFinalStageMatch={true}
                        groupMatch={losers as any}
                        customClasses={classes.matchCard}
                    />
                </>
            );
        }
        return (
            <>
                <div className={classes.ranking}>
                    <p>
                        <b>{rankingPositionBottom}</b>
                    </p>
                </div>
                <MatchCard
                    matchName={rankingPositionBottom}
                    matchType={FinalStageTypes.final}
                    isFinalStageMatch={true}
                    customClasses={classes.matchCard}
                />
            </>
        );
    }, [finals, semifinals]);

    const firstSemiFinal = useMemo(() => {
        const match = semifinals.find(semifinal => semifinal?.name === rankingPositionTop);
        if (match) {
            return (
                <MatchCard
                    matchName={rankingPositionTop}
                    matchType={FinalStageTypes.semifinal}
                    isTop={true}
                    isSemifinal={true}
                    isFinalStageMatch={true}
                    groupMatch={match as any}
                    customClasses={classes.matchCard}
                />
            );
        }
        return (
            <MatchCard
                matchName={rankingPositionTop}
                matchType={FinalStageTypes.semifinal}
                isTop={true}
                isSemifinal={true}
                isFinalStageMatch={true}
                customClasses={classes.matchCard}
            />
        );
    }, [semifinals]);

    const secondSemiFinal = useMemo(() => {
        const match = semifinals.find(semifinal => semifinal?.name === rankingPositionBottom);
        if (match) {
            return (
                <MatchCard
                    matchName={rankingPositionBottom}
                    matchType={FinalStageTypes.semifinal}
                    isFinalStageMatch={true}
                    isSemifinal={true}
                    groupMatch={match as any}
                    customClasses={classes.matchCard}
                />
            );
        }
        return (
            <MatchCard
                matchName={rankingPositionBottom}
                matchType={FinalStageTypes.semifinal}
                isFinalStageMatch={true}
                isSemifinal={true}
                customClasses={classes.matchCard}
            />
        );
    }, [semifinals]);

    function createFakeMatch(match1: FinalStageMatchWithTeamsAndSocieties, match2: FinalStageMatchWithTeamsAndSocieties) {
        const homeWin = +match1.homeGoals > +match1.awayGoals ? match1.home : match1.away;
        const awayWin = +match2.homeGoals > +match2.awayGoals ? match2.home : match2.away;
        const homeLose = match1.home.id === homeWin.id ? match1.away : match1.home;
        const awayLose = match2.home.id === awayWin.id ? match2.away : match2.home;
        return {
            winners: {
                name: match1.name,
                home: homeWin,
                away: awayWin,
                homeTeam: homeWin.id,
                awayTeam: awayWin.id,
                type: FinalStageTypes.final,
                year: match1.year
            },
            losers: {
                name: match2.name,
                home: homeLose,
                away: awayLose,
                homeTeam: homeLose.id,
                awayTeam: awayLose.id,
                type: FinalStageTypes.final,
                year: match1.year
            }
        };
    }

    return (
        <div className={classes.cardGroup}>
            <div className={classes.cards}>
                <div className={classes.top}>
                    <div className={classes.rankingTransparent}></div>
                    {firstSemiFinal}
                </div>
                <div className={classes.bottom}>
                    <div className={classes.rankingTransparent}></div>
                    {secondSemiFinal}
                </div>
            </div>
            <Vector />
            <div className={classes.cards}>
                {firstFinal}
                {secondFinal}
            </div>
        </div>
    );
};

export default GroupCard;
