import { AxiosContext } from './axiosContext';
import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { env } from 'environments';
import { Team } from 'models';

interface TeamApi {
    getAllTeams: () => Promise<Team[]>;
}

const TeamContext = createContext<TeamApi>({} as TeamApi);

const TeamProvider: FC<PropsWithChildren> = ({ children }) => {
    const { getRequest } = useContext(AxiosContext);

    const getAllTeams = useCallback(async () => {
        const { data } = await getRequest(`${env.reactAppSocketUrl}${env.apiUrl}/teams`);
        return data;
    }, [getRequest]);

    return <TeamContext.Provider value={{ getAllTeams }}>{children}</TeamContext.Provider>;
};

export { TeamContext, TeamProvider };
