import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const FinalStages: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 2.44444V0H0V2.44444H4.75V19.5556H0V22H20V19.5556H15.25V2.44444H20ZM12.75 19.5556H7.25V2.44444H12.75V19.5556Z"
                fill={fill}
            />
        </svg>
    );
};
