import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Reload: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22.483" height="31.228" viewBox="0 0 22.483 31.228">
            <path
                id="reload"
                d="M5.952,28.648l3.821-3.041a10.746,10.746,0,0,1-6.625-18.3L4.209,8.365A9.248,9.248,0,0,0,9.894,24.113l-3-3.767,1.174-.934,4.145,5.21a.749.749,0,0,1-.12,1.054l-5.21,4.146Zm11.322-7.19A9.248,9.248,0,0,0,11.589,5.71l3,3.767-1.174.934L9.267,5.2a.749.749,0,0,1,.12-1.053L14.6,0l.934,1.174L11.708,4.216a10.746,10.746,0,0,1,6.626,18.3Z"
                transform="translate(0.5 0.703)"
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
                fill={fill}
            />
        </svg>
    );
};
