import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GroupYears } from 'helpers';
import { Group, GroupMatchTeamWithRank } from 'models';

interface GroupState {
    groups: Group[];
    activeTab: GroupYears;
    ranking: any[];
}

const name = 'group';
const initialState: GroupState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const groupActions = { ...slice.actions };
export const groupReducer = slice.reducer;

function createInitialState() {
    return {
        groups: [],
        activeTab: GroupYears.u14,
        ranking: []
    };
}

function createReducers() {
    return { setGroups, setActiveTab, setRanking };

    function setGroups(state: GroupState, action: PayloadAction<Group[]>) {
        state.groups = action.payload;
    }

    function setActiveTab(state: GroupState, action: PayloadAction<GroupYears>) {
        if (state.activeTab === action.payload) return;
        state.activeTab = action.payload;
    }

    function setRanking(state: GroupState, action: PayloadAction<{ ranking: GroupMatchTeamWithRank[]; groupId: string }>) {
        const index = state.ranking.findIndex(currentRanking => currentRanking.groupId === action.payload.groupId);
        if (index >= 0) {
            state.ranking[index] = { groupId: action.payload.groupId, ranking: action.payload.ranking };
            return;
        }
        state.ranking.push({ groupId: action.payload.groupId, ranking: action.payload.ranking });
    }
}
