import Modal from 'components/modal/Modal';
import { DEFAULT_PATTERN, FieldProps, GroupYears, SetState } from 'helpers';
import { SocietyUpdate, SocietyWithGroups } from 'models';
import { FC, useContext, useCallback, useMemo, useState } from 'react';
import Form from 'components/form/Form';
import classes from './EditSocietyModal.module.scss';
import { SocietyContext } from 'context';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, societyActions } from 'store';
import { teamActions } from 'store/team.slice';
import CropImage from 'components/cropImage/CropImage';
import { TextButton } from 'components/buttons';

type EditSocietyModalProps = FC<{ society: SocietyWithGroups | null; setSociety: SetState<SocietyWithGroups | null> }>;

const EditSocietyModal: EditSocietyModalProps = ({ setSociety, society }) => {
    const { deleteSociety, updateSociety } = useContext(SocietyContext);
    const { societies, image } = useSelector((x: RootState) => x.societies);
    const { groups } = useSelector((x: RootState) => x.group);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const dispatch = useDispatch();
    const groupOptions14 = useMemo(() => groups.filter(group => group.year === GroupYears.u14).map(g => g.name), [groups]);

    const FIELDS: FieldProps[] = [
        {
            name: 'name',
            value: society?.name,
            validators: { required: true, pattern: DEFAULT_PATTERN }
        },
        {
            name: 'group',
            validators: { required: false },
            value: society?.u14,
            options: groupOptions14
        }
    ];

    const handleCloseModal = useCallback(() => {
        setSociety(null);
        dispatch(societyActions.setImage(''));
    }, [dispatch, setSociety]);

    const handleCloseConfirmModal = useCallback(() => {
        setIsConfirmModalOpen(false);
        dispatch(societyActions.setImage(''));
    }, [dispatch]);

    const onDeleteHandler = useCallback(async () => {
        const res = await deleteSociety(society?.id as string);
        if (!res) return;
        const newSocieties = societies.filter(s => s.id !== society?.id);
        dispatch(societyActions.setSocieties(newSocieties));
        dispatch(teamActions.removeDeletedTeams(society?.id!));
        handleCloseModal();
        setIsConfirmModalOpen(false);
    }, [deleteSociety, dispatch, handleCloseModal, societies, society?.id]);

    const onUpdateHandler = useCallback(
        async (formValue: any) => {
            const societyUpdate: SocietyUpdate = {
                name: formValue.name,
                image,
                id: society?.id!
            };
            const u14 = groups.filter(group => group.year === GroupYears.u14).find(g => g.name.includes(formValue.group))?.id;
            const u16 = groups.filter(group => group.year === GroupYears.u16).find(g => g.name.includes(formValue.group))?.id;
            const res = await updateSociety(societyUpdate, u14!, u16!);
            if (!res) return;
            setSociety(null);
            dispatch(societyActions.updateSociety(res.society));
            dispatch(teamActions.updateTeam([res.u14, res.u16]));
        },
        [dispatch, groups, image, setSociety, society, updateSociety]
    );

    return (
        <>
            <Modal title="Modifica società" handleClose={handleCloseModal} isOpen={!!society}>
                <div className={classes.wrapper}>
                    <CropImage src={image} />
                    <Form
                        onClick={() => setIsConfirmModalOpen(true)}
                        canDelete={true}
                        buttonLabel="Salva"
                        fields={FIELDS}
                        handleSubmitFunction={onUpdateHandler}
                    />
                </div>
            </Modal>
            <Modal title="Elimina Società" handleClose={handleCloseConfirmModal} isOpen={isConfirmModalOpen}>
                <div className={classes.confirm}>
                    <span>Hai deciso di eliminare:</span>
                    <b>{society?.name}</b>
                    <span>Continuare?</span>
                </div>
                <div className={classes.delete}>
                    <TextButton className="alert" onClick={onDeleteHandler}>
                        Elimina
                    </TextButton>
                </div>
            </Modal>
        </>
    );
};

export default EditSocietyModal;
