import { FC, useState } from 'react';
import Modal from 'components/modal/Modal';
import { IconButton } from 'components/buttons';
import { Copy, Share } from 'components/icons';
import classes from './ShareModal.module.scss';
import Notice from 'components/notice/Notice';

const ShareModal: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNoticeOpen, setIsNoticeOpen] = useState(false);

    const url = window.location.protocol + '//' + window.location.host;

    const copyLinkHandler = () => {
        navigator.clipboard.writeText(url);
        setIsNoticeOpen(!isNoticeOpen);
        setIsModalOpen(false);
    };
    return (
        <>
            <IconButton className="transparent mediumLight" icon={<Share />} onClick={() => setIsModalOpen(true)} />
            <Modal
                customClasses={classes.share}
                title="Condividi link"
                handleClose={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
            >
                <span className={classes.text}>
                    Copia ed invia questo link per condividere la classifica del campionato in visualizzazione.
                </span>
                <div className={classes.container}>
                    <span className={classes.link}>
                        <a href={url}>{url}</a>
                    </span>
                    <IconButton className="primary" icon={<Copy />} onClick={copyLinkHandler} />
                </div>
            </Modal>
            <Notice isOpen={isNoticeOpen} message="Link copiato correttamente" setIsOpen={setIsNoticeOpen} />
        </>
    );
};

export default ShareModal;
