import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ResponseTopics, socketActions } from 'store';

export const useSocketListener = (topic: ResponseTopics, listener: (...args: any[]) => void) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(socketActions.createListeners({ topic, listener }));
        return () => {
            dispatch(
                socketActions.removeListener({
                    responseTopic: topic
                })
            );
        };
    }, [listener]);
};
