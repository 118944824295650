import { FinalStagesContext } from 'context';
import { SetState } from 'helpers';
import { useIsBackoffice } from 'hook';
import { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import classes from '../editGroupMatchModal/EditGroupMatch.module.scss';
import Modal from 'components/modal/Modal';
import { TextButton } from 'components/buttons';
import { RootState } from 'store';
import { FinalStageTypes, GroupMatchWithTeamsAndSocieties } from 'models';
import SocietyPicture from 'components/societyPicture/SocietyPicture';

type EditFinalStageMatchesModalProps = FC<{
    groupMatch: GroupMatchWithTeamsAndSocieties;
    isModalOpen: boolean;
    setIsModalOpen: SetState<boolean>;
    matchName: string;
    matchType: FinalStageTypes;
}>;

const EditFinalStageMatchesModal: EditFinalStageMatchesModalProps = ({
    groupMatch,
    isModalOpen,
    setIsModalOpen,
    matchName,
    matchType
}) => {
    const { activeTab } = useSelector((x: RootState) => x.group);
    const { createFakeFinalStageMatch } = useContext(FinalStagesContext);
    const isBackoffice = useIsBackoffice();
    const {
        register,
        handleSubmit,
        formState: { isValid, isDirty }
    } = useForm({ mode: 'onChange' });

    function resetModal() {
        setIsModalOpen(false);
    }

    async function onSaveHandler(formValues: any) {
        if (!formValues.time) return;
        const matchDay = new Date(formValues.date as Date).setHours(
            +formValues.time!.split(':')[0],
            +formValues.time!.split(':')[1]
        );
        const res = await createFakeFinalStageMatch({ matchDay, name: matchName, year: activeTab, type: matchType });
        if (!res) return;
        setIsModalOpen(false);
    }

    if (!isBackoffice && !groupMatch) return <></>;

    return (
        <Modal customClasses={classes.editGroupMatchModal} handleClose={resetModal} isOpen={isModalOpen} title="Partita">
            <form onSubmit={handleSubmit(onSaveHandler)}>
                <div className={`${classes.homeGoals} ${!isBackoffice ? classes.homeGoalsTransparent : classes.gray} `}>
                    <div className={` ${classes.homeGoal} ${classes.homeGoalLeft}`}>
                        <input
                            disabled={true}
                            defaultValue={''}
                            placeholder="-"
                            type="number"
                            {...register('homeGoals', { max: 100, min: 0 })}
                        />
                        {groupMatch ? (
                            <SocietyPicture size="medium" src={groupMatch?.home?.Society.image} />
                        ) : (
                            <div className={classes.teamLogo}></div>
                        )}
                    </div>
                    <div className={` ${classes.homeGoal} ${classes.homeGoalRight}`}>
                        {groupMatch ? (
                            <SocietyPicture size="medium" src={groupMatch?.away?.Society.image} />
                        ) : (
                            <div className={classes.teamLogo}></div>
                        )}
                        <input
                            disabled={true}
                            defaultValue={''}
                            placeholder="-"
                            type="number"
                            {...register('awayGoals', { max: 100, min: 0 })}
                        />
                    </div>
                </div>
                <br />
                <div className={classes.societyName}>
                    <p>
                        <b>
                            {groupMatch?.home?.Society.name} - {groupMatch?.away?.Society.name}
                        </b>
                    </p>
                </div>
                <br />
                <div className={`${!isBackoffice && classes.homeCal} ${classes.calendar}`}>
                    <input placeholder="Data" type="date" disabled={!isBackoffice} {...register('date', { max: '2024/12/31' })} />
                    <input type="time" disabled={!isBackoffice} {...register('time')}></input>
                </div>

                <br />
                {isBackoffice && (
                    <div className={classes.buttons}>
                        <TextButton className="transparent shadow" type="button" onClick={resetModal}>
                            Annulla
                        </TextButton>
                        <TextButton className="primary text" disabled={!isValid || !isDirty} type="submit">
                            Salva
                        </TextButton>
                    </div>
                )}
            </form>
        </Modal>
    );
};

export default EditFinalStageMatchesModal;
