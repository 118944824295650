import { FC } from 'react';
import classes from './GroupIcon.module.scss';

type GroupIconProps = FC<{
    item: string;
    isSmall?: boolean;
}>;

const GroupIcon: GroupIconProps = ({ item, isSmall = false }) => {
    return (
        <div className={`${item === 'F' ? classes.f : classes.l} ${classes.initial} ${isSmall && classes.small}`}>
            <b>{item}</b>
        </div>
    );
};

export default GroupIcon;
