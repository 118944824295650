import { FC, useCallback, useContext, useEffect } from 'react';
import classes from './Backoffice.module.scss';
import { SocietyProvider, GroupProvider, FinalStagesContext } from 'context';
import { TeamProvider } from 'context/teamContext';
import { GroupYears, ToggleType } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RootState, finalStagesActions, groupMatchActions } from 'store';
import CompetitionManagement from './competitionManagement/CompetitionManagement';
import { GroupMatchContext } from 'context/groupMatchContext';
import FirstStage from 'components/firstStage/FirstStage';
import RankingTable from 'components/rankingTable/RankingTable';
import { useSocketListener } from 'hook';
import { Navigate } from 'react-router';
import SecondStage from 'components/secondStage/SecondStage';
import { FinalStageMatchWithTeamsAndSocieties } from 'models';

const Backoffice: FC = () => {
    const { activeTab } = useSelector((x: RootState) => x.societies);
    const { getAllGroupsMatches } = useContext(GroupMatchContext);
    const { user } = useSelector<RootState>(x => x.auth) as AuthState;
    const { getAllFinalStagesMatches } = useContext(FinalStagesContext);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            const matches = await getAllGroupsMatches();
            const finalStageMatches = await getAllFinalStagesMatches();
            const u14 = finalStageMatches.filter(match => match.year === GroupYears.u14);
            const u16 = finalStageMatches.filter(match => match.year === GroupYears.u16);
            dispatch(finalStagesActions.setFinalStageMatchesU14(u14));
            dispatch(finalStagesActions.setFinalStageMatchesU16(u16));
            dispatch(groupMatchActions.setGroupsMatches(matches));
        })();
    }, [dispatch, getAllGroupsMatches]);

    const checkMatchYearAndSetFinalStages = (matches: FinalStageMatchWithTeamsAndSocieties[], year?: GroupYears, id?: string) => {
        if (id) {
            const match = matches.find(match => match.id === id);
            dispatch(finalStagesActions.addFinalStageFinals(match!));
            return;
        }
        if (matches?.length && year === GroupYears.u14) {
            const filteredMatches = matches.filter(match => match.year === year);
            dispatch(finalStagesActions.setFinalStageMatchesU14(filteredMatches));
            return;
        }
        const filteredMatches = matches.filter(match => match.year === year);
        dispatch(finalStagesActions.setFinalStageMatchesU16(filteredMatches));
    };

    const updateGroupMatchHandler = useCallback(
        (message: any) => dispatch(groupMatchActions.replaceGroupMatch(message.payload)),
        [dispatch]
    );

    const updateFinalStageMatchHandler = useCallback(
        (message: any) => dispatch(finalStagesActions.replaceFinalStageMatch(message.payload)),
        [dispatch]
    );

    const addGroupsMatches = useCallback(
        (message: any) => dispatch(groupMatchActions.setGroupsMatches(message.payload)),
        [dispatch]
    );

    const addFinalStages = useCallback(
        (message: any) => {
            checkMatchYearAndSetFinalStages(message.payload.finalStagesMatches, message.payload.year, message.payload.finalId);
        },
        [dispatch]
    );

    useSocketListener('update-match', updateGroupMatchHandler);
    useSocketListener('update-final-stage-match', updateFinalStageMatchHandler);
    useSocketListener('competition-created', addGroupsMatches);
    useSocketListener('final-stages-created', addFinalStages);

    if (!user) return <Navigate to="/login" />;

    return (
        <div
            className={`${classes.container} ${
                activeTab === ToggleType.groups || activeTab === ToggleType.finalStages ? classes.shorterNavbar : null
            } `}
        >
            <SocietyProvider>
                <GroupProvider>
                    <TeamProvider>
                        {activeTab === ToggleType.backoffice && <CompetitionManagement />}
                        {activeTab === ToggleType.groups && <FirstStage />}
                        {activeTab === ToggleType.finalStages && <SecondStage />}
                        {activeTab === ToggleType.ranking && <RankingTable />}
                    </TeamProvider>
                </GroupProvider>
            </SocietyProvider>
        </div>
    );
};

export default Backoffice;
