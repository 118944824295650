import { SvgIconProps } from 'helpers';

export const Arrow: SvgIconProps = () => {
    return (
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.2175 16L0.2175 9C-0.0724999 8.71 -0.0724999 8.23 0.2175 7.94L7.2175 0.94L8.2775 2L1.8075 8.47L8.2775 14.94L7.2175 16Z"
                fill="white"
            />
        </svg>
    );
};
