import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const LineVectors: SvgIconProps = ({ fill = colors.primary600 }) => {
    return (
        <svg width="26" height="2" viewBox="0 0 26 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="26" y2="1" stroke="#008FC1" strokeWidth="2" />
        </svg>
    );
};
