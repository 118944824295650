import { useEffect } from 'react';

export const useDebounce = (cb: any, delay: number) => {
    useEffect(() => {
        const debounceHandler = setTimeout(() => {
            cb();
        }, delay);

        return () => {
            clearTimeout(debounceHandler);
        };
    }, [delay, cb]);
};
