import Routing from './Routing';
import { history } from 'helpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import 'styles/main.scss';
import { AxiosProvider } from 'context';

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();
    return (
        <>
            <Provider store={store}>
                <AxiosProvider>
                    <Routing />
                </AxiosProvider>
            </Provider>
        </>
    );
}

export default App;
