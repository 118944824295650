import classes from './Footer.module.scss';

const Footer = () => {
    const url = 'https://www.eagleprojects.it';

    return (
        <div className={classes.footer}>
            <span>DEVELOPED BY&nbsp;</span>
            <b>
                <a className={classes.link} href={url} target="_blank" rel="noreferrer">
                    EAGLEPROJECT SPA
                </a>
            </b>
        </div>
    );
};
export default Footer;
