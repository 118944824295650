import { Dispatch, FC, SetStateAction } from 'react';

export type SvgIconProps = FC<{
    fill?: string;
    zoom?: number;
    isFull?: boolean;
}>;

export enum ToggleType {
    backoffice = 'backoffice',
    groups = 'groups',
    finalStages = 'finalStages',
    ranking = 'ranking'
}

export interface FieldProps {
    placeholder?: string;
    name: string;
    validators?: any;
    errors?: any;
    options?: any[];
    value?: any;
    disabled?: boolean;
    toggle?: FieldPropsToggle;
    multiSelect?: any;
    isSecretPassword?: boolean;
}

export interface FieldPropsToggle {
    isChecked: boolean;
    onClick: any;
}

export type SetState<S> = Dispatch<SetStateAction<S>>;

export type HTMLButtonType = 'button' | 'submit' | 'reset';

export type TableButtonProps = {
    icon: JSX.Element;
    action: (item: any) => any;
    customClasses: string;
    id: string;
    disabled?: ((item: any) => boolean) | boolean;
};

export enum GroupYears {
    u14 = 'u14',
    u16 = 'u16'
}
