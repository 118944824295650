import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Edit: SvgIconProps = ({ fill = colors.gray600, zoom = 1 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16.653 16.647" style={{ zoom }}>
            <path
                id="edit"
                d="M1708.835,17.222a.5.5,0,0,0-.148-.359l-5.046-5.044a.518.518,0,0,0-.717,0l-9.584,9.586a.512.512,0,0,0-.142.276l-1.009,6.053a.506.506,0,0,0,.5.589.52.52,0,0,0,.079-.005l6.058-1.01a.508.508,0,0,0,.274-.142l9.587-9.586a.5.5,0,0,0,.148-.358m-16.092,10.928Zm1.425-6.143,7.042-7.041.736-.736,1.337-1.337,4.329,4.327h0l-1.338,1.338-.736.736h0l-7.043,7.04-5.195.867Z"
                transform="translate(-1692.182 -11.676)"
                fill={fill}
            />
        </svg>
    );
};
