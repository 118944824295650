import { FC } from 'react';
import classes from './Navbar.module.scss';
import BackofficeNavbar from './backofficeNavbar/BackofficeNavbar';
import ToggleButtonsNavbar from 'components/navbar/toggleButtonsNavbar/ToggleButtonsNavbar';
import ToggleGroupNavbar from 'components/navbar/toggleGroupNavbar/ToggleGroupNavbar';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ToggleType } from 'helpers';

const Navbar: FC<{ isBackOffice: boolean }> = ({ isBackOffice }) => {
    const { activeTab } = useSelector((x: RootState) => x.societies);
    return (
        <div className={classes.generalContainer}>
            <BackofficeNavbar isBackOffice={isBackOffice} />
            <ToggleButtonsNavbar isBackOffice={isBackOffice} />
            {activeTab !== ToggleType.backoffice && activeTab !== ToggleType.ranking && <ToggleGroupNavbar />}
        </div>
    );
};

export default Navbar;
