import { LineVectors, Vector10, Vector11 } from 'components/icons';
import classes from '../../FinalStage.module.scss';
import { FC } from 'react';

const Vector: FC = () => {
    return (
        <div className={classes.vector}>
            <Vector10 />
            <div>
                <LineVectors />
            </div>
            <Vector11 />
        </div>
    );
};

export default Vector;
