import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Ranking: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.4637 11.48C24.1466 11.24 27.6846 4.28 27.6846 0.75C27.6846 0.34 27.3655 0 26.9808 0H0.703846C0.319077 0 0 0.34 0 0.75C0 4.27 3.538 11.24 8.22092 11.48C9.48785 14.45 11.1677 16.92 13.1385 17.41V20.01H10.0885C9.82569 20.01 9.58169 20.17 9.45969 20.43L7.58277 24.43C7.47015 24.66 7.48892 24.94 7.61092 25.16C7.74231 25.38 7.96754 25.52 8.21154 25.52H19.4731C19.7171 25.52 19.9423 25.39 20.0737 25.16C20.2051 24.94 20.2145 24.66 20.1018 24.43L18.2249 20.43C18.1029 20.18 17.8589 20.01 17.5962 20.01H14.5462V17.41C16.5075 16.92 18.1874 14.45 19.4637 11.48ZM18.3375 24H9.35646L10.5295 21.5H17.1645L18.3375 24ZM20.0925 9.88C21.1998 6.83 21.8849 3.56 22.0257 1.5H26.2206C25.8077 4.44 23.2082 9.11 20.0925 9.88ZM1.464 1.5H5.65892C5.79969 3.56 6.48477 6.83 7.59215 9.88C4.47646 9.11 1.87692 4.44 1.464 1.5ZM7.06662 1.5H20.618C20.2895 6.46 16.9955 16 13.8423 16C10.6891 16 7.39508 6.46 7.06662 1.5Z"
                fill={fill}
            />
        </svg>
    );
};
