import { FC } from 'react';
import Button from '../button/Button';
import { HTMLButtonType } from 'helpers';

type IconButtonProps = FC<{
    type?: HTMLButtonType;
    className: string;
    disabled?: boolean;
    icon: JSX.Element;
    onClick: (param?: any) => any;
}>;

const IconButton: IconButtonProps = ({ icon, onClick, className, disabled, type = 'button' }) => {
    return (
        <Button type={type} onClick={onClick} className={`icon ${className}`} disabled={disabled}>
            {icon}
        </Button>
    );
};

export default IconButton;
