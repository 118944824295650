import { SvgIconProps } from 'helpers';

export const Podium: SvgIconProps = () => {
    return (
        <svg width="26" height="20" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.75 8H17.5V0.75C17.5 0.34 17.16 0 16.75 0H8.75C8.34 0 8 0.34 8 0.75V4H0.75C0.34 4 0 4.34 0 4.75V16.75C0 17.16 0.34 17.5 0.75 17.5H24.75C25.16 17.5 25.5 17.16 25.5 16.75V8.75C25.5 8.34 25.16 8 24.75 8ZM1.5 5.5H8V16H1.5V5.5ZM9.5 4.75V1.5H16V16H9.5V4.75ZM24 16H17.5V9.5H24V16Z"
                fill="#2B3939"
            />
        </svg>
    );
};
