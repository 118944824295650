import { FC } from 'react';
import classes from './SocietyPicture.module.scss';

type SocietyPictureProps = FC<{
    src: string;
    size?: 'normal' | 'large' | 'medium';
}>;

const SocietyPicture: SocietyPictureProps = ({ src, size = 'normal' }) => {
    return (
        <div className={`${classes.societyPic} ${classes[size]}`}>
            {src ? <img alt="" src={src} /> : <img alt="placeholder" src="placeholder.png" />}
        </div>
    );
};

export default SocietyPicture;
