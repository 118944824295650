import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'components/form/Form';
import { EMAIL_PATTERN, FieldProps, ToggleType, history } from 'helpers';
import { RootState, societyActions } from 'store';
import { AuthState, authActions } from 'store/auth.slice';
import classes from '../Login.module.scss';
import { Arrow } from 'components/icons';
import { IconButton } from 'components/buttons';

const FIELDS: FieldProps[] = [
    { placeholder: 'username', name: 'email', validators: { required: true, pattern: EMAIL_PATTERN } },
    { placeholder: 'password', name: 'password', validators: { required: true, minLength: 4 }, isSecretPassword: true }
];

const LoginForm: FC = () => {
    const dispatch = useDispatch<any>();
    const { tokenExpiration, error } = useSelector<RootState>(x => x.auth) as AuthState;
    const handleSubmit = useCallback(
        (event: { email: string; password: string }) => {
            dispatch(authActions.login({ email: event.email, password: event.password }));
            dispatch(societyActions.setActiveTab(ToggleType.backoffice));
        },
        [dispatch]
    );

    useEffect(() => {
        if (tokenExpiration && tokenExpiration > Date.now()) history.navigate('/backoffice');
    }, [handleSubmit, tokenExpiration]);

    return (
        <div className={classes.formLog}>
            <div className={classes.header}>
                <IconButton icon={<Arrow />} className={`transparent ${classes.arrow}`} onClick={() => history.navigate('/')} />
                <h5>login</h5>
            </div>

            <Form isLogin fields={FIELDS} handleSubmitFunction={handleSubmit} buttonLabel="Login" />
            {error && <div className={classes.error}>Password o email non corretta.</div>}
        </div>
    );
};

export default memo(LoginForm);
