import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Team } from 'models';

interface TeamState {
    teams: Team[];
}

const name = 'team';
const initialState: TeamState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const teamActions = { ...slice.actions };
export const teamReducer = slice.reducer;

function createInitialState() {
    return {
        teams: []
    };
}

function createReducers() {
    return { setTeams, addTeam, removeDeletedTeams, updateTeam };

    function setTeams(state: TeamState, action: PayloadAction<Team[]>) {
        state.teams = action.payload;
    }

    function addTeam(state: TeamState, action: PayloadAction<Team[]>) {
        state.teams = state.teams.concat(action.payload);
    }

    function removeDeletedTeams(state: TeamState, action: PayloadAction<string>) {
        state.teams = state.teams.filter(team => team.societyId !== action.payload);
    }

    function updateTeam(state: TeamState, action: PayloadAction<Team[]>) {
        action.payload.forEach(team => {
            const index = state.teams.findIndex(t => t.id === team.id);
            if (index === -1) return;
            state.teams[index] = team;
        });
    }
}
