import { AxiosContext } from './axiosContext';
import { Group } from 'models';
import { FC, PropsWithChildren, createContext, useCallback, useContext } from 'react';
import { env } from 'environments';

interface GroupApi {
    getGroups: () => Promise<Group[]>;
}

const GroupContext = createContext<GroupApi>({} as GroupApi);

const GroupProvider: FC<PropsWithChildren> = ({ children }) => {
    const { getRequest } = useContext(AxiosContext);

    const getGroups = useCallback(async () => {
        const { data } = await getRequest(`${env.reactAppSocketUrl}${env.apiUrl}/groups`);
        return data;
    }, [getRequest]);

    return <GroupContext.Provider value={{ getGroups }}>{children}</GroupContext.Provider>;
};

export { GroupContext, GroupProvider };
