import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Logout: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24.953 25.5">
            <g id="logout" transform="translate(-1369.226 -130.368)">
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M1389.127,143.907v-1.5h-17.548l3.636-4.363-1.155-.959-4.662,5.595a.748.748,0,0,0,0,.957l4.66,5.591,1.155-.959-3.636-4.362Z"
                    fill={fill}
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M1378.679,130.368v4h1.5v-2.5h12.5v22.5h-12.5v-2.5h-1.5v4h15.5v-25.5Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
