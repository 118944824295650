import { RootState } from 'store';
import Groups from './groups/Groups';
import { useSelector } from 'react-redux';
import { GroupYears } from 'helpers';
import { useMemo } from 'react';
import { FinalStagesProvider } from 'context';

const FirstStage = () => {
    const { activeTab } = useSelector((x: RootState) => x.group);
    const { groupsMatches } = useSelector((x: RootState) => x.groupsMatches);

    const u14Matches = useMemo(
        () => groupsMatches.filter(match => match.home.year === GroupYears.u14).sort((a, b) => a.matchDay - b.matchDay),
        [groupsMatches]
    );
    const u16Matches = useMemo(
        () => groupsMatches.filter(match => match.home.year === GroupYears.u16).sort((a, b) => a.matchDay - b.matchDay),
        [groupsMatches]
    );

    return (
        <FinalStagesProvider>
            {activeTab === GroupYears.u14 && <Groups groupsMatches={u14Matches} />}
            {activeTab === GroupYears.u16 && <Groups groupsMatches={u16Matches} />}
        </FinalStagesProvider>
    );
};

export default FirstStage;
