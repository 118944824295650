import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Vector11: SvgIconProps = ({ fill = colors.primary600 }) => {
    return (
        <svg width="20" height="162" viewBox="0 0 20 162" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 161L7.00002 161C3.68631 161 1.00002 158.314 1.00002 155L1 7C1 3.68629 3.68629 0.999998 7 0.999998L20 0.999997"
                stroke="#008FC1"
                strokeWidth="2"
            />
        </svg>
    );
};
