import { FC, PropsWithChildren, useCallback, memo, useState, ChangeEvent, useMemo } from 'react';
import { TableContainer, Paper, Table as MuiTable, TablePagination, Toolbar } from '@mui/material';
import TableHead from './tableHead/TableHead';
import TableBody from './tableBody/TableBody';
import { TableButtonProps } from 'helpers';
import classes from './Table.module.scss';

type TableProps = FC<
    PropsWithChildren<{
        buttons?: TableButtonProps[];
        collection: any[];
        headers: string[];
    }>
>;

const Table: TableProps = ({ buttons, collection, headers }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const handleListCollection = useMemo(() => {
        const pageItems = collection.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        if (pageItems.length === 0) {
            setPage(0);
            return collection.slice(0, rowsPerPage);
        }
        return pageItems;
    }, [collection, page, rowsPerPage, setPage]);

    const handleChangeItemsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }, []);

    if (!collection || !collection.length) return <></>;
    return (
        <TableContainer component={Paper} className={classes.table}>
            <MuiTable>
                <TableHead headers={headers} buttons={buttons} />
                <TableBody buttons={buttons} collection={handleListCollection} headers={headers} />
            </MuiTable>
            <TablePagination
                style={{ display: 'none' }}
                labelRowsPerPage="Righe per pagina"
                rowsPerPageOptions={[50, 75, 100]}
                component="div"
                count={collection.length}
                rowsPerPage={rowsPerPage}
                page={page > 0 && collection.length <= page * rowsPerPage ? 0 : page}
                onPageChange={(_event: unknown, newPage: number) => setPage(newPage)}
                onRowsPerPageChange={handleChangeItemsPerPage}
            />
        </TableContainer>
    );
};

export default memo(Table);
