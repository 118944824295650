import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { GroupYears } from 'helpers';
import FinalStage from './finalStage/FinalStage';

const SecondStage = () => {
    const { activeTab } = useSelector((x: RootState) => x.group);
    const { finalStagesMatchesU14, finalStagesMatchesU16 } = useSelector((x: RootState) => x.finalStages);

    return (
        <>
            {activeTab === GroupYears.u14 && <FinalStage finalStage={finalStagesMatchesU14} />}
            {activeTab === GroupYears.u16 && <FinalStage finalStage={finalStagesMatchesU16} />}
        </>
    );
};

export default SecondStage;
