import { FC, useCallback, useRef, useState } from 'react';
import { useCropImageReducer } from '../cropImage/CropImage.reducer';
import { IconTextButton } from 'components/buttons';
import { Upload } from 'components/icons';
import classes from './CropImage.module.scss';
import CropperModal from './cropperModal/CropperModal';
import SocietyPicture from 'components/societyPicture/SocietyPicture';

type CropImageProps = FC<{ src: any }>;

const CropImage: CropImageProps = ({ src }) => {
    const cropImageReducer = useCropImageReducer();
    const inputRef = useRef<HTMLInputElement>();
    const [uploadInputKey, setUploadInputKey] = useState(0);

    const onUploadClick = () => {
        inputRef.current?.click();
    };

    const onDropFileHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const filesList = e.target.files;
            if (filesList && filesList.length > 0) {
                const file = filesList[0];
                cropImageReducer.setValue('crop', undefined);
                const reader = new FileReader();
                reader.addEventListener('load', () => cropImageReducer.setValue('imgSrc', reader.result?.toString() || ''));
                reader.readAsDataURL(file);
                cropImageReducer.setValue('isModalOpen', true);
                setUploadInputKey(prev => prev + 1);
            }
        },
        [cropImageReducer]
    );

    return (
        <>
            <input
                key={uploadInputKey}
                className={classes.noDisplay}
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={onDropFileHandler}
                ref={inputRef as any}
                placeholder="Upload"
            />
            <CropperModal cropImageReducer={cropImageReducer} />
            <div className={classes.containerPhoto}>
                <SocietyPicture src={src} size="large" />
                <IconTextButton onClick={onUploadClick} className="transparent shadow" icon={<Upload />}>
                    <span>Carica foto</span>
                </IconTextButton>
            </div>
        </>
    );
};

export default CropImage;
