import { GroupMatchWithTeamsAndSocieties } from 'models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface GroupMatchState {
    groupsMatches: GroupMatchWithTeamsAndSocieties[];
}

const name = 'groupsMatches';
const initialState: GroupMatchState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const groupMatchActions = { ...slice.actions };
export const groupMatchReducer = slice.reducer;

function createInitialState() {
    return {
        groupsMatches: []
    };
}

function createReducers() {
    return { setGroupsMatches, updateGroupMatch, replaceGroupMatch };

    function setGroupsMatches(state: GroupMatchState, action: PayloadAction<GroupMatchWithTeamsAndSocieties[]>) {
        state.groupsMatches = action.payload;
    }

    function updateGroupMatch(
        state: GroupMatchState,
        action: PayloadAction<{ id: string; date?: number; homeGoals?: number; awayGoals?: number }>
    ) {
        const groupMatch = state.groupsMatches.find(groupMatch => groupMatch.id === action.payload.id);
        if (!groupMatch) return;
        if (action.payload.date) groupMatch.matchDay = action.payload.date;
        groupMatch.homeGoals = +action.payload.homeGoals!;
        groupMatch.awayGoals = +action.payload.awayGoals!;
    }

    function replaceGroupMatch(state: GroupMatchState, action: PayloadAction<GroupMatchWithTeamsAndSocieties>) {
        const index = state.groupsMatches.findIndex(match => match.id === action.payload.id);
        if (index < 0) return;
        state.groupsMatches[index] = action.payload;
    }
}
