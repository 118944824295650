import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Login: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.8276 9.7722C25.0315 9.7722 23.3274 10.4722 22.0546 11.745C20.9586 12.841 20.2868 14.2482 20.1242 15.7685L4.07993 15.7755V22.5213H5.579V17.2746L8.08215 17.2746L8.08215 20.5273L9.58122 20.5273L9.58122 17.2746L20.1313 17.2746C20.506 20.6475 23.3698 23.2709 26.8346 23.2709C28.7014 23.2709 30.3772 22.5143 31.6005 21.291C32.8238 20.0677 33.5804 18.3777 33.5804 16.5251C33.5804 14.6725 32.8804 13.0249 31.6076 11.7521C30.3348 10.4793 28.6377 9.7722 26.8346 9.77927L26.8276 9.7722ZM32.0814 16.5251C32.0814 19.4242 29.7267 21.7789 26.8346 21.7718C23.9355 21.7718 21.5808 19.4171 21.5879 16.5251C21.5879 15.125 22.1324 13.8027 23.1223 12.8128C24.1123 11.8228 25.4346 11.2783 26.8346 11.2783C28.2347 11.2783 29.557 11.8228 30.5469 12.8128C31.5369 13.8027 32.0814 15.125 32.0814 16.5251Z"
                fill={fill}
            />
        </svg>
    );
};
