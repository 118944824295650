import { HTMLButtonType } from 'helpers';
import { FC, PropsWithChildren } from 'react';

type ButtonProps = FC<
    PropsWithChildren<{
        disabled?: boolean;
        onClick?: () => any;
        type?: HTMLButtonType;
        className: string;
        isTooltipNecessary?: boolean;
        tooltipMessage?: string;
    }>
>;

const Button: ButtonProps = ({ children, onClick, className, type, disabled }) => {
    return (
        <button type={type} className={className} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
