import { GroupYears } from 'helpers';
import { GroupMatchTeam } from './groupMatch.model';

export enum FinalStageTypes {
    final = 'final',
    semifinal = 'semifinal'
}

export interface FinalStage {
    id: string;
    homeGoals: number;
    awayGoals: number;
    name: string;
    homeTeam: string;
    awayTeam: string;
    matchDay: number;
    year: GroupYears;
    type: FinalStageTypes;
    competitionId: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface FinalStageMatchWithTeamsAndSocieties extends FinalStage {
    home: GroupMatchTeam;
    away: GroupMatchTeam;
}

export interface FinalStageMatchesCreation {
    name: string;
    homeTeam?: string;
    awayTeam?: string;
    year: GroupYears;
    type: FinalStageTypes;
    matchDay?: number;
}

export interface FinalStageMatchUpdate {
    id: string;
    homeGoals: number;
    awayGoals: number;
    matchDay: number;
}
