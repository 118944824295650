import { FC, useCallback, useRef } from 'react';
import { useCropImageReducer } from '../CropImage.reducer';
import { TextButton } from 'components/buttons';
import Modal from 'components/modal/Modal';
import ReactCrop from 'react-image-crop';
import classes from './CropperModal.module.scss';
import { useDebounce } from 'hook';
import { canvasPreview, centerAspectCrop } from 'helpers';
import 'react-image-crop/dist/ReactCrop.css';
import { useDispatch } from 'react-redux';
import { societyActions } from 'store';

type CropperModalProps = FC<{
    cropImageReducer: ReturnType<typeof useCropImageReducer>;
}>;

const CropperModal: CropperModalProps = ({ cropImageReducer }) => {
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const dispatch = useDispatch();

    const onImageLoad = useCallback(
        (e: React.SyntheticEvent<HTMLImageElement>) => {
            const { width, height } = e.currentTarget;
            cropImageReducer.setValue('crop', centerAspectCrop(width, height, 1));
        },
        [cropImageReducer]
    );

    useDebounce(async () => {
        if (cropImageReducer.doneCrop?.width && cropImageReducer.doneCrop?.height && imgRef.current && previewCanvasRef.current)
            canvasPreview(imgRef.current, previewCanvasRef.current, cropImageReducer.doneCrop);
    }, 100);

    const onSaveHandler = useCallback(async () => {
        const base64 = previewCanvasRef.current?.toDataURL('image/*', 1) as string;
        const image = new Image();
        image.src = base64;
        await new Promise(resolve => (image.onload = resolve));
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d')!;
        const maxWidthOrHeight = 500;
        const scaleFactor = Math.min(1, maxWidthOrHeight / Math.max(image.width, image.height));
        canvas.width = image.width * scaleFactor;
        canvas.height = image.height * scaleFactor;
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        const resizedBase64 = canvas.toDataURL('image/jpeg', 0.9);
        dispatch(societyActions.setImage(resizedBase64));
        cropImageReducer.setValue('isModalOpen', false);
    }, [cropImageReducer, dispatch]);

    return (
        <Modal
            customClasses={classes.cropImageModal}
            title="Carica foto"
            isOpen={cropImageReducer.isModalOpen}
            handleClose={() => cropImageReducer.setValue('isModalOpen', false)}
        >
            <div className={classes.wrapper}>
                <div className={classes.crop}>
                    <div className={classes.images}>
                        {!!cropImageReducer.imgSrc && (
                            <div className={classes.container}>
                                <ReactCrop
                                    className={classes.cropper}
                                    crop={cropImageReducer.crop}
                                    onChange={(_, percentCrop) => cropImageReducer.setValue('crop', percentCrop)}
                                    onComplete={c => cropImageReducer.setValue('doneCrop', c)}
                                    aspect={1}
                                >
                                    <img alt="SocietyPicture" ref={imgRef} src={cropImageReducer.imgSrc} onLoad={onImageLoad} />
                                </ReactCrop>
                            </div>
                        )}
                        {!!cropImageReducer.doneCrop && <canvas className={classes.canvas} ref={previewCanvasRef} />}
                    </div>
                </div>
                <div className={classes.buttons}>
                    <TextButton
                        onClick={() => cropImageReducer.setValue('isModalOpen', false)}
                        type="submit"
                        className="text shadow"
                    >
                        Annulla
                    </TextButton>
                    <TextButton
                        disabled={!cropImageReducer.imgSrc}
                        onClick={onSaveHandler}
                        type="submit"
                        className="primary text shadow"
                    >
                        Salva
                    </TextButton>
                </div>
            </div>
        </Modal>
    );
};

export default CropperModal;
