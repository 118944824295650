import { FC, useMemo } from 'react';
import classes from './RankingTable.module.scss';
import GridTable from 'components/gridTable/GridTable';
import { GridColDef } from '@mui/x-data-grid';
import SocietyPicture from 'components/societyPicture/SocietyPicture';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { FinalStageMatchWithTeamsAndSocieties, FinalStageTypes, GroupMatchTeam } from 'models';
import Footer from 'components/footer/Footer';
import { useIsBackoffice } from 'hook';
import colors from 'styles/modules/colors.module.scss';
import { SnackbarAlert } from 'components/icons';

const fake = { image: '', ranking: '', total: '', u14: '', u16: '' };

const collection = [
    { ...fake, id: 1 },
    { ...fake, id: 2 },
    { ...fake, id: 3 },
    { ...fake, id: 4 },
    { ...fake, id: 5 },
    { ...fake, id: 6 },
    { ...fake, id: 7 },
    { ...fake, id: 8 }
];

const U14_POINTS = [
    [20, 14],
    [12, 10],
    [8, 6],
    [4, 2]
];

const U16_POINTS = [
    [30, 21],
    [18, 15],
    [12, 9],
    [6, 3]
];

const RankingTable: FC = () => {
    const { finalStagesMatchesU14, finalStagesMatchesU16 } = useSelector((x: RootState) => x.finalStages);
    const { societies } = useSelector((x: RootState) => x.societies);
    const isBackoffice = useIsBackoffice();

    const ranking = useMemo(() => {
        const u14Finals = finalStagesMatchesU14.filter(match => match.type === FinalStageTypes.final);
        const u16Finals = finalStagesMatchesU16.filter(match => match.type === FinalStageTypes.final);
        if (
            u14Finals.filter(final => final.awayGoals !== null && final.homeGoals !== null).length !== 4 &&
            u16Finals.filter(final => final.awayGoals !== null && final.homeGoals !== null).length !== 4
        )
            return [];
        const u14Ranking = formatFinalRanking(U14_POINTS, u14Finals);
        const u16Ranking = formatFinalRanking(U16_POINTS, u16Finals);
        const ranking = societies.map(society => {
            const u14Team = u14Ranking.find(rank => rank.societyId === society.id);
            const u16Team = u16Ranking.find(rank => rank.societyId === society.id);
            return {
                image: society.image,
                total: (u14Team?.points ?? 0) + (u16Team?.points ?? 0),
                u16: u16Team?.points,
                u14: u14Team?.points
            };
        });
        return ranking
            .sort((society1, society2) => society2.total - society1.total)
            .map((rank, index) => ({
                ...rank,
                id: index + 1
            }));
    }, [finalStagesMatchesU14, finalStagesMatchesU16]);

    const HEADERS: GridColDef[] = [
        {
            field: 'image',
            headerName: '',
            minWidth: 10,
            maxWidth: 100,
            flex: 3,
            editable: false,
            sortable: false,
            renderCell(image) {
                return ranking.length ? <SocietyPicture src={image.value} /> : <div className={classes.teamLogo}></div>;
            }
        },
        {
            field: 'ranking',
            headerName: 'Classifica',
            minWidth: 5,
            maxWidth: 200,
            flex: 4,
            editable: false,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell(index) {
                return ranking.length ? (
                    <div className={classes.ranking}>
                        <b>{index.id + '°'}</b>
                    </div>
                ) : (
                    <></>
                );
            }
        },
        {
            field: 'total',
            headerName: 'Totale',
            minWidth: 5,
            maxWidth: 150,
            flex: 4,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell(field) {
                return ranking.length ? (
                    <div>
                        <b>{field.value}</b>
                    </div>
                ) : (
                    <></>
                );
            }
        },
        {
            field: 'u14',
            headerName: 'Ragazzi',
            minWidth: 20,
            maxWidth: 150,
            flex: 4,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell(field) {
                return ranking.length ? (
                    <div>
                        <b>{field.value}</b>
                    </div>
                ) : (
                    <></>
                );
            }
        },
        {
            field: 'u16',
            headerName: 'Allievi',
            minWidth: 20,
            maxWidth: 100,
            flex: 4,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell(field) {
                return ranking.length ? (
                    <div>
                        <b>{field.value}</b>
                    </div>
                ) : (
                    <></>
                );
            }
        }
    ];

    function formatFinalRanking(ranking: any[], matches: FinalStageMatchWithTeamsAndSocieties[]) {
        const teamsPoints: any[] = [];
        matches.forEach((match, index) => {
            const matchWinner = match.homeGoals > match.awayGoals ? match.home : match.away;
            const matchLoser = match.homeGoals > match.awayGoals ? match.away : match.home;
            const { winner, loser } = setMatchPoints(matchWinner, matchLoser, ranking[index]);
            teamsPoints.push(winner);
            teamsPoints.push(loser);
        });
        return teamsPoints;
    }

    function setMatchPoints(winner: GroupMatchTeam, loser: GroupMatchTeam, points: number[]) {
        return {
            winner: {
                societyId: winner?.Society.id,
                points: points[0]
            },
            loser: {
                societyId: loser?.Society.id,
                points: points[1]
            }
        };
    }

    return (
        <div className={classes.container}>
            <div className={classes.table}>
                <GridTable collection={ranking.length ? ranking : collection} headers={HEADERS} />
                <div>
                    {!ranking.length && !isBackoffice && (
                        <div className={classes.alert}>
                            <SnackbarAlert fill={colors.primary600} />
                            <b>I risultati compariranno al termine delle competizioni</b>
                        </div>
                    )}
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default RankingTable;
