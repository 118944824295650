import { SvgIconProps } from 'helpers';
import colors from 'styles/modules/colors.module.scss';

export const Groups: SvgIconProps = ({ fill = colors.gray100 }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_97_1410)">
                <path d="M20 2.44444V0H0V2.44444H8.75V19.5556H0V22H20V19.5556H11.25V2.44444H20Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_97_1410">
                    <rect width="20" height="22" fill={fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
