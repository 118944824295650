import { FC, memo } from 'react';
import { TableRow, TableCell, TableHead as MuiTableHead } from '@mui/material';
import { TableButtonProps } from 'helpers';

type TableHeadProps = FC<{
    buttons?: TableButtonProps[];
    headers: string[];
}>;

const TableHead: TableHeadProps = ({ buttons, headers }) => {
    return (
        <MuiTableHead>
            <TableRow>
                {headers.map(header => {
                    if (header === 'image' || header === 'ranking') {
                        return (
                            <TableCell key={header}>
                                <></>
                            </TableCell>
                        );
                    }
                    return <TableCell key={header}>{header as any}</TableCell>;
                })}
                {buttons ? buttons.map(button => <TableCell key={button.id}>&nbsp;</TableCell>) : null}
            </TableRow>
        </MuiTableHead>
    );
};
export default memo(TableHead);
