import { FC, PropsWithChildren, memo } from 'react';
import Button from '../button/Button';
import { HTMLButtonType } from 'helpers';

type TextButtonProps = FC<
    PropsWithChildren<{
        disabled?: boolean;
        onClick?: (event?: any) => any;
        type?: HTMLButtonType;
        className: string;
    }>
>;

const TextButton: TextButtonProps = ({ disabled, children, onClick, className, type }) => {
    return (
        <Button type={type} onClick={onClick} className={`text ${className}`} disabled={disabled}>
            {children}
        </Button>
    );
};

export default memo(TextButton);
