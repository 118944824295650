import { FormControl, MenuItem, Select } from '@mui/material';
import { FC, useMemo } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import classes from './Multiselect.module.scss';

interface FieldProps {
    id: string;
    options: string[];
    defaultValue?: string[];
    validators?: any;
}

type MultiselectProps = FC<{ field: FieldProps; register: UseFormRegister<FieldValues>; onChange: any }>;

const Multiselect: MultiselectProps = ({ field, register, onChange }) => {
    const itemsGenerator = useMemo(() => {
        return field.options.map(item => (
            <MenuItem key={`${item}_key`} value={item}>
                {item}
            </MenuItem>
        ));
    }, [field]);

    return (
        <FormControl className={classes.matSelect} variant="filled">
            <Select
                id={field.id}
                className={classes.select}
                multiple
                displayEmpty
                disableUnderline={true}
                defaultValue={field.defaultValue}
                {...register(field.id, { ...field.validators, onChange, value: field.defaultValue })}
            >
                {itemsGenerator}
            </Select>
        </FormControl>
    );
};

export default Multiselect;
